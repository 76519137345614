import { Route } from '../types';
import { BasedataIcon, ControlIcon, DashboardIcon, LockIcon, ProfileIcon, SettingsIcon } from '@bp/ui-components';
import { AuthClients } from '../routes/AuthClients/AuthClients';
import { Applications } from '../routes/Applications/Applications';
import { ShadowProfiles } from '../routes/ShadowProfiles/ShadowProfiles';
import { LoginPage } from '../routes/Login/LoginPage';

// Module routes - lazy loaded to enable code splitting
import { Organizations } from '../routes/Organizations/Organizations';
import { Dashboard } from '../routes/Dashboard/Dashboard';
import { Settings } from '../routes/Settings/Settings';
import { Profiles } from '../routes/Profiles/Profiles';
import { Groups } from '../routes/Groups/Groups';
import { FederatedClients } from '../routes/FederatedClients/FederatedClients';

export const routes: Array<Route> = [
  {
    key: 'login',
    title: 'Login',
    path: '/login',
    component: LoginPage,
    inSidebar: false,
    icon: undefined,
    link: '/login',
    onlyOrganisationContext: false,
  },
  {
    key: 'router-dashboard',
    title: 'Dashboard',
    description: 'Dashboard',
    path: '/',
    link: '',
    component: Dashboard,
    inSidebar: true,
    icon: <DashboardIcon />,
    onlyOrganisationContext: false,
  },
  {
    key: 'router-organizations',
    title: 'Organizations',
    description: 'Organizations',
    path: '/organizations',
    component: Organizations,
    inSidebar: true,
    icon: <BasedataIcon />,
    link: 'organizations',
    onlyOrganisationContext: false,
  },
  {
    key: 'auth-clients',
    title: 'AuthClients',
    description: 'AuthClients',
    path: '/auth-clients',
    component: AuthClients,
    inSidebar: true,
    icon: <LockIcon />,
    link: 'auth-clients',
    onlyOrganisationContext: false,
  },
  {
    key: 'federated-clients',
    title: 'FederatedClients',
    description: 'FederatedClients',
    path: '/federated-clients',
    component: FederatedClients,
    inSidebar: true,
    icon: <LockIcon />,
    link: 'federated-clients',
    onlyOrganisationContext: false,
  },
  {
    key: 'applications',
    title: 'Applications',
    description: 'Applications',
    path: '/applications',
    component: Applications,
    inSidebar: true,
    icon: <ControlIcon />,
    link: 'applications',
    onlyOrganisationContext: false,
  },
  {
    key: 'router-profiles',
    title: 'Profiles',
    description: 'Profiles',
    path: '/profiles',
    component: Profiles,
    inSidebar: true,
    icon: <ProfileIcon />,
    link: 'profiles',
    onlyOrganisationContext: true,
  },
  {
    key: 'shadow-profiles',
    title: 'Shadow Profiles',
    description: '',
    path: '/shadow-profiles',
    component: ShadowProfiles,
    inSidebar: true,
    icon: <ProfileIcon className={'svg-icon'} />,
    link: 'shadow-profiles',
    onlyOrganisationContext: true,
  },
  {
    key: 'router-groups',
    title: 'Groups',
    description: 'Groups',
    path: '/groups',
    component: Groups,
    inSidebar: true,
    icon: <BasedataIcon />,
    link: 'groups',
    onlyOrganisationContext: true,
  },
  {
    key: 'router-settings',
    title: 'Settings',
    description: 'Settings',
    path: '/settings',
    component: Settings,
    inSidebar: false,
    icon: <SettingsIcon />,
    link: 'settings',
    onlyOrganisationContext: false,
  },
];
