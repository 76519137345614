import { FieldArray, useFormikContext } from 'formik';
import { PimListProfileResponseType } from '../../graphql/types';
import { FormBlockHeader } from '../../../../ui/Form/FormBlockHeader';
import React, { useState } from 'react';
import { AddIcon, Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { MultiValue, SingleValue } from 'react-select';
import { GroupAndProfileRole, ProfileFormType } from '../ProfileForm';
import { useTranslation } from 'react-i18next';
export type ProfileRolesProps = {
  profileList?: PimListProfileResponseType[];
};
export const ProfileRoles = ({ profileList }: ProfileRolesProps) => {
  const { values, setFieldValue } = useFormikContext<ProfileFormType>();
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<PimListProfileResponseType | null>();
  const [selectValue, setSelectValue] = useState<SingleValue<SelectOptionType> | undefined>();
  const list = profileList
    ? [...profileList].filter((p) => values.roles.profiles.filter((rp) => rp.uuid === p.uuid).length === 0)
    : [];
  return (
    <div className={'form-block'}>
      <FormBlockHeader title={`Profile roles`} />
      <FieldArray name={'roles.profiles'}>
        {(arrayHelpers) => {
          return (
            <>
              {values.roles.profiles.map((value, i) => {
                return (
                  <div className={'form-group'} key={i}>
                    <div className={'form-col'}>
                      <div className={'form-row'}>
                        {value.label}
                        <span className={'font-xs'}>{value.uuid}</span>
                      </div>
                      <div className={'form-row'}>
                        <Select
                          name={`roles.profiles.${i}.values`}
                          options={[]}
                          isMulti={true}
                          onChange={(option) => {
                            const opt = option as MultiValue<SelectOptionType>;
                            setFieldValue(
                              `roles.profiles.${i}.values`,
                              opt.map((o) => o.value),
                            );
                          }}
                          value={value.values.map((v) => ({ value: v, label: v }))}
                          allowCreate={true}
                          isClearable={true}
                          className={'full'}
                          placeholder={'Add roles'}
                        />
                        <Button
                          className={'align-self-center'}
                          hierarchy={'tertiary'}
                          icon={<DeleteIcon className={'svg-icon'} />}
                          onClick={() => arrayHelpers.remove(i)}
                        />
                      </div>
                    </div>
                    <div className={'form-col'}></div>
                  </div>
                );
              })}
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Select
                      value={selectValue ?? undefined} // TODO refactor when null value are allowed
                      className={'third'}
                      label={t('profiles.roles.profiles.relation') as string}
                      name={'profileList'}
                      options={list?.map((p) => ({ value: p, label: `${p.lastName}, ${p.firstName}` })) ?? []}
                      onChange={(option) => {
                        const data = option as SingleValue<SelectOptionType>;
                        const value = data?.value as PimListProfileResponseType;
                        setSelectValue(data ?? undefined);
                        setSelectedProfile(value);
                      }}
                      isClearable={true}
                    />
                    <Button
                      className={'align-self-center'}
                      disabled={!selectedProfile}
                      icon={<AddIcon className={'svg-icon'} />}
                      onClick={() => {
                        setSelectValue(undefined);
                        arrayHelpers.push({
                          uuid: selectedProfile?.uuid,
                          label: `${selectedProfile?.lastName}, ${selectedProfile?.firstName}`,
                          values: [],
                        } as GroupAndProfileRole);
                        setSelectValue(null);
                      }}
                    >
                      {t('profiles.roles.profiles.add')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
