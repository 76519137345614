import { FC, PropsWithChildren, useContext, useState } from 'react';
import { Input, Select, SelectOptionType } from '@bp/ui-components';
import { useField, useFormikContext } from 'formik';
import { ShadowProfileFromType } from '../ShadowProfilesForm/ShadowProfilesForm';
import { useQuery } from 'urql';
import {
  PimPropertiesInOrganizationDocument,
  PimPropertiesInOrganizationQuery,
  PimPropertiesInOrganizationQueryVariables,
} from '../../../../client/graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { HasPropertyClause } from '@bp/pim-shadow-conditions';

type PropertiesSelectType = PropsWithChildren & {
  name: string;
};

type EdgeType = {
  properties: { scope: string };
  node: { __typename?: 'Property' | undefined; name: string; value: string };
};

export const PropertiesSelect: FC<PropertiesSelectType> = ({ name }) => {
  const formik = useFormikContext<ShadowProfileFromType>();
  const [field] = useField<HasPropertyClause>(name);
  const [scope, setScope] = useState<string | null>(null);
  const [scopeInputValue, setScopeInputValue] = useState<string | null>(null);
  const { t } = useTranslation();
  const { organization } = useContext(OrganizationContext);

  const [{ data }] = useQuery<PimPropertiesInOrganizationQuery, PimPropertiesInOrganizationQueryVariables>({
    query: PimPropertiesInOrganizationDocument,
    variables: { organizationUuid: organization?.uuid ?? '', scope },
  });

  function getPropertyOptions({
    data,
    labelValue,
  }: {
    data: PimPropertiesInOrganizationQuery | undefined;
    labelValue: 'propertyName' | 'propertyValue';
  }): SelectOptionType[] {
    if (!data) return [];
    const organizations = data.organizations;
    const names: SelectOptionType[] = [];
    organizations.forEach((organization) => {
      const propertiesConnections = [
        organization.propertiesConnection,
        ...organization.groups.map((group) => group.propertiesConnection),
        ...organization.profiles.map((profile) => profile.propertiesConnection),
      ];
      propertiesConnections.forEach((connection) => {
        connection.edges.forEach((edge) => {
          names.push({
            label: labelValue === 'propertyName' ? `${edge.node.name}` : `${edge.node.value}`,
            value: edge as EdgeType,
          });
        });
      });
    });
    return names;
  }

  return (
    <>
      <Input
        className={'quarter'}
        name={'scope'}
        onChange={(e) => setScopeInputValue(e.target.value)}
        onBlur={(e) => setScope(e.target.value)}
        value={scopeInputValue ?? ''}
        label={t('propertyScope') as string}
      ></Input>
      <Select
        className={'quarter'}
        label={t('propertyName') as string}
        name={'propertyName'}
        allowCreate={true}
        defaultValue={{ value: field.value.value, label: field.value.name }}
        options={getPropertyOptions({ data, labelValue: 'propertyName' })}
        onChange={(options) => {
          const o = options as SingleValue<SelectOptionType>;
          const value = o?.value as EdgeType;
          formik.setFieldValue(name, {
            relation: 'HAS_PROPERTY',
            name: value.node?.name ?? '',
            scope: value.properties.scope,
            value: value.node?.value,
          } as HasPropertyClause);
        }}
      />
      <Input
        className={'quarter'}
        label={t('propertyValue') as string}
        name={'propertyValue'}
        value={field.value ? field.value.value : ''}
        onChange={(event) => {
          formik.setFieldValue(name, {
            relation: 'HAS_PROPERTY',
            name: field.value?.name ?? '',
            scope: field.value?.scope ?? scope,
            value: event.target.value,
          } as HasPropertyClause);
        }}
      />
    </>
  );
};
