import React, { FC, useMemo } from 'react';
import { useMutation } from 'urql';
import {
  PimCreateApplicationDocument,
  PimCreateApplicationMutation,
  PimCreateApplicationMutationVariables,
  PimUpdateApplicationDocument,
  PimUpdateApplicationMutation,
  PimUpdateApplicationMutationVariables,
  usePimAllAuthClientsQuery,
  usePimApplicationsQuery,
} from '../../../client/graphql-client-defs';
import { Form, Formik, FormikHelpers } from 'formik';
import { ApplicationsResponse } from '../graphql/types';
import { Button, Input, Select, SelectOptionType, showToast } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { FormBlockHeader } from '../../../ui/Form/FormBlockHeader';
import { LicensedOrganizationsForm } from './LicensedOrganizationsForm';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { MultiValue, SingleValue } from 'react-select';

type ApplicationsFormProps = {
  uuid: string | null;
  closeForm: () => void;
};

export const ApplicationsForm: FC<ApplicationsFormProps> = ({ uuid, closeForm }) => {
  const context = useMemo(() => ({ additionalTypenames: ['Applications'] }), []);
  const { t } = useTranslation();
  const [{ data, fetching }] = usePimApplicationsQuery({
    variables: {
      where: {
        uuid: uuid,
      },
    },
    context,
  });

  const [{ data: authClientData }] = usePimAllAuthClientsQuery({
    variables: {},
    context,
  });

  const [, create] = useMutation<PimCreateApplicationMutation, PimCreateApplicationMutationVariables>(
    PimCreateApplicationDocument,
  );
  const [, update] = useMutation<PimUpdateApplicationMutation, PimUpdateApplicationMutationVariables>(
    PimUpdateApplicationDocument,
  );

  const handleSubmit = async (values: ApplicationsResponse, formHelpers: FormikHelpers<ApplicationsResponse>) => {
    let result;
    if (!values.uuid || values.uuid === '') {
      result = await create(
        {
          input: {
            name: values.name,
            clientId: values.clientId,
            licensedOrganizations: {
              connect: values.licensedOrganizationsConnection.edges.map((licensedOrganization) => {
                return {
                  where: { node: { uuid: licensedOrganization.node.uuid } },
                  edge: {
                    notAfter: licensedOrganization.properties.notAfter,
                    notBefore: licensedOrganization.properties.notBefore,
                  },
                };
              }),
            },
            accessGrantedOrganizations: {
              connect: values.accessGrantedOrganizations.map((accessGrantedOrganization) => {
                return { where: { node: { uuid: accessGrantedOrganization.uuid } } };
              }),
            },
            accessGrantedProfiles: {
              connect: values.accessGrantedProfiles.map((accessGrantedProfile) => {
                return { where: { node: { uuid: accessGrantedProfile.uuid } } };
              }),
            },
          },
        },
        context,
      );
    } else {
      result = await update({
        where: {
          uuid: values.uuid,
        },
        update: {
          name: values.name,
          clientId: values.clientId,
          accessGrantedProfiles: [
            {
              disconnect: [{}],
              connect: values.accessGrantedProfiles.map((accessGrantedProfile) => {
                return { where: { node: { uuid: accessGrantedProfile.uuid } } };
              }),
            },
          ],
          accessGrantedOrganizations: [
            {
              disconnect: [{}],
              connect: values.accessGrantedOrganizations.map((accessGrantedOrganization) => {
                return { where: { node: { uuid: accessGrantedOrganization.uuid } } };
              }),
            },
          ],
          licensedOrganizations: [
            {
              disconnect: [{}],
              connect: values.licensedOrganizationsConnection.edges.map((licensedOrganization) => {
                return {
                  where: { node: { uuid: licensedOrganization.node.uuid } },
                  edge: {
                    notAfter: licensedOrganization.properties?.notAfter,
                    notBefore: licensedOrganization.properties?.notBefore,
                  },
                };
              }),
            },
          ],
        },
      });
    }

    if (!result || result.error) {
      // sentry ?
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
    }
    formHelpers.resetForm();
    closeForm();
  };
  const application: ApplicationsResponse =
    uuid && data && data.applications
      ? data?.applications[0]
      : {
          name: '',
          clientId: '',
          licensedOrganizationsConnection: {
            totalCount: 0,
            edges: [],
          },
          accessGrantedOrganizationsConnection: { totalCount: 0, edges: [] },
          accessGrantedProfiles: [],
          accessGrantedProfilesConnection: { totalCount: 0, edges: [] },
          uuid: '',
          accessGrantedOrganizations: [],
          licensedOrganizations: [],
        };
  const accessGrantedOrganizationsOptions = useCreateSelectOptions(data?.organizations, 'uuid', 'name');
  const accessGrantedProfilesOptions: SelectOptionType[] =
    data?.profiles.map((profile) => {
      return { label: `${profile.lastName}, ${profile.firstName}`, value: profile.uuid };
    }) ?? [];

  const clientIdOptions: SelectOptionType[] =
    authClientData?.authClients?.map((s) => ({
      value: s.clientId,
      label: `${s.clientName} (${s.clientId})`,
    })) ?? [];
  clientIdOptions.push({ value: 'pim-admin-client', label: 'Admin Frontend Alt' });
  clientIdOptions.push({ value: 'admin-frontend-client', label: 'Admin Frontend' });
  clientIdOptions.push({ value: 'bp-frontend-client', label: 'BP Frontend' });
  clientIdOptions.push({ value: 'planung-frontend-client', label: 'Planung Frontend' });
  clientIdOptions.push({ value: 'procurat-dashboard-client', label: 'Procurat! Dashboard' });

  return (
    <Formik onSubmit={handleSubmit} initialValues={application}>
      {({ handleChange, values, isSubmitting, errors, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <div className={'form-block'}>
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Input
                      name={'name'}
                      onChange={handleChange}
                      value={values.name}
                      label={t('applications.name') as string}
                      className={'half'}
                    />
                    <Select
                      className={'half'}
                      name={'clientId'}
                      label={t('applications.clientId') as string}
                      placeholder={t('applications.clientId') as string}
                      allowCreate={true}
                      isClearable={true}
                      isSearchable
                      options={clientIdOptions}
                      value={
                        clientIdOptions.find((o) => o.value === application.clientId) ?? {
                          value: application.clientId,
                          label: application.clientId,
                        }
                      }
                      onChange={(options) => {
                        const opt = options as SingleValue<SelectOptionType>;
                        const value = opt && opt.value ? { name: opt.value, isNew: opt.__isNew__ } : null;
                        setFieldTouched('clientId', true);
                        setFieldValue('clientId', value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'form-block'}>
              <FormBlockHeader title={t('applications.licensedOrganizations')} />
              <LicensedOrganizationsForm />
            </div>
            <div className={'form-block'}>
              <FormBlockHeader title={t('applications.accessGrantedOrganizations')} />
              <Select
                name={'accessGrantedOrganizations'}
                options={accessGrantedOrganizationsOptions}
                defaultValue={values.accessGrantedOrganizations.map((orga) => ({ value: orga.uuid, label: orga.name }))}
                onChange={(options) => {
                  const opt = options as MultiValue<SelectOptionType>;
                  setFieldValue(
                    'accessGrantedOrganizations',
                    opt.map((o) => ({ uuid: o.value, name: o.label })),
                  );
                  setFieldTouched('accessGrantedOrganizations', true);
                }}
                isClearable={true}
                isMulti={true}
                isSearchable
              />
            </div>
            <div className={'form-block'}>
              <FormBlockHeader title={t('applications.accessGrantedProfiles')} />
              <Select
                name={'accessGrantedProfiles'}
                options={accessGrantedProfilesOptions}
                defaultValue={values.accessGrantedProfiles.map((profile) => ({
                  value: profile.uuid,
                  label: `${profile.firstName} ${profile.lastName}`,
                }))}
                onChange={(options) => {
                  const opt = options as MultiValue<SelectOptionType>;
                  setFieldValue(
                    'accessGrantedProfiles',
                    opt.map((o) => ({ uuid: o.value })),
                  );
                  setFieldTouched('accessGrantedProfiles', true);
                }}
                isClearable={true}
                isMulti={true}
              />
            </div>
            <div className={'modal-bottom'}>
              <Button type='submit' disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
