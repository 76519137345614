import React, { FC, useEffect, useState } from 'react';
import { Button } from '@bp/ui-components';
import styles from './App.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { hasAuthParams, useAuth } from 'react-oidc-context';

type RequireAuthProps = {
  children?: React.ReactNode;
};

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const auth = useAuth();
  const { t } = useTranslation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const accessDenied = params.get('error') === 'access_denied';
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return (
      <div className={styles.loginwrapper} data-cy='layoutWrapper'>
        <div>Loading...</div>
      </div>
    );
  }

  if (auth.error) {
    return (
      <div className={styles.loginwrapper} data-cy='layoutWrapper'>
        <div>
          Oops... {auth.error.message} ({auth.error.name})
        </div>
        <div className={styles.vspace}>
          <Button hierarchy='primary' onClick={() => auth.removeUser()} fullWidth={true}>
            {t('auth.logout')}
          </Button>
        </div>
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <div className={styles.loginwrapper} data-cy='layoutWrapper'>
        {!accessDenied && (
          <div className={styles.vspace}>
            <Button hierarchy='primary' onClick={() => auth.signinRedirect()} fullWidth={true}>
              {t('auth.login')}
            </Button>
          </div>
        )}
        {accessDenied && (
          <>
            <div className={styles.loginseparator}>{t(params.get('error_description') ?? 'auth.error')}</div>
            <div className={styles.vspace}>
              Leider hat der Benutzer mit dem Sie aktuell in TK-Login angemeldet sind keinen Zugriff auf diese
              Anwendung.
              <br />
              <br />
              Bitte melden Sie sich ab und dann mit einem anderen Benutzer an.
            </div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => auth.signoutRedirect()} fullWidth={true}>
                {t('auth.logout')}
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }

  return <>{children}</>;
};
