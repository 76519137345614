import React, { useMemo, useState } from 'react';
import { Button, Row, Select } from '@bp/ui-components';
import { SelectOptionType } from '@bp/ui-components/dist/@bp/lib/Select/Select';
import { PimProfileTableType, ProfilesTable } from '../../../ui/ProfilesTable/ProfilesTable';
import {
  PimListGroupsDocument,
  PimListGroupsQuery,
  PimListGroupsQueryVariables,
  PimUpdateGroupDocument,
  PimUpdateGroupMutation,
  PimUpdateGroupMutationVariables,
} from '../../../client/graphql-client-defs';
import { ListPlainOrganizationsType } from '../../Organizations/graphql/types';
import { useMutation, useQuery } from 'urql';
import { PimProfileQueryType } from '../../Profiles/graphql/types';
import { ListGroups, ListGroupsList } from '../graphql/types';

interface GroupsFormProps {
  groupUuid: string;
  organization: ListPlainOrganizationsType;
}

export const GroupsUserForm = ({ groupUuid, organization }: GroupsFormProps) => {
  const context = useMemo(() => ({ additionalTypenames: ['Group'] }), []);

  const [currentSelectedProfile, setCurrentSelectedProfile] = useState<SelectOptionType>();

  const [, updateGroup] = useMutation<PimUpdateGroupMutation, PimUpdateGroupMutationVariables>(PimUpdateGroupDocument);

  const [{ data }] = useQuery<PimListGroupsQuery, PimListGroupsQueryVariables>({
    query: PimListGroupsDocument,
    context,
    variables: {
      options: {
        limit: 1,
        offset: 0,
      },
      where: {
        uuid: groupUuid,
      },
      organizationUuid: organization.uuid,
    },
  });

  const initialValues: ListGroups = {
    uuid: '',
    name: '',
    __typename: 'Group',
    profiles: [],
    profilesConnection: {
      __typename: 'GroupProfilesConnection',
      totalCount: 0,
    },
    propertiesConnection: { edges: [] },
  };

  const group: ListGroups =
    data && data.groups ? (data.groups.length > 0 ? data.groups[0] : initialValues) : initialValues;
  const list: ListGroupsList[] | undefined = data && data.profiles;

  const createProfileSelectList = (): SelectOptionType[] | undefined => {
    return list
      ?.filter((item) => {
        return item.user !== null && item.organization.uuid === organization.uuid;
      })
      .map((item) => {
        return {
          label: `${item.lastName}, ${item.firstName}  ( ${item.email} )`,
          value: item.uuid,
        } as SelectOptionType;
      });
  };

  const selectOptions = useMemo(createProfileSelectList, [organization.uuid, list]);

  const addProfile = async () => {
    if (currentSelectedProfile) {
      if (group.profiles && list) {
        const profile = list.find((item) => {
          return item.uuid === currentSelectedProfile.value;
        });
        if (profile) {
          await updateGroup(
            {
              update: {
                profiles: [
                  {
                    connect: [{ where: { node: { uuid: profile.uuid } } }],
                  },
                ],
              },
              where: {
                uuid: groupUuid,
              },
            },
            context,
          );
        }
      }
    }
    setCurrentSelectedProfile(undefined);
  };

  const handleDelete = async (row: Row<PimProfileTableType>) => {
    await updateGroup(
      {
        update: {
          profiles: [
            {
              disconnect: [{ where: { node: { uuid: row.original.uuid } } }],
            },
          ],
        },
        where: {
          uuid: groupUuid,
        },
      },
      context,
    );
  };

  return (
    <div>
      <div className={'form-row'}>
        {selectOptions && (
          <Select
            label={'Profiles'}
            name={'profiles'}
            options={selectOptions}
            defaultValue={currentSelectedProfile}
            onChange={(event) => {
              setCurrentSelectedProfile(event as SelectOptionType);
            }}
            isSearchable={true}
            isMulti={false}
            className={'three-thirds'}
          />
        )}
        <Button hierarchy={'primary'} onClick={addProfile}>
          Add
        </Button>
      </div>
      {group && group.profiles && (
        <ProfilesTable
          showSelect={false}
          showBulkEdit={false}
          showAddButton={false}
          profiles={group.profiles as PimProfileQueryType[]}
          onDelete={handleDelete}
          onUserDelete={undefined}
          onDeleteConfirmText={(row) => {
            return `Disconnect ${row.original.email}`;
          }}
        />
      )}
    </div>
  );
};
