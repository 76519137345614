import { Select, SelectOptionType, SpinnerIcon } from '@bp/ui-components';
import React, { FC, PropsWithChildren, Suspense, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { GroupsSelect } from './GroupsSelect';
import { RolesSelect } from './RolesSelect';
import { PropertiesSelect } from './PropertiesSelect';
import { useTranslation } from 'react-i18next';
import { ShadowConditionClause } from '@bp/pim-shadow-conditions';
import { useField } from 'formik';

type ConditionsFormProps = PropsWithChildren & {
  name: string;
};

export const ConditionsForm: FC<ConditionsFormProps> = ({ name }) => {
  const options: SelectOptionType[] = useMemo(() => {
    return ['HAS_GROUP', 'HAS_ROLE', 'HAS_PROPERTY'].map((r) => ({
      value: r,
      label: r,
    }));
  }, []);
  const [field] = useField<ShadowConditionClause>(name);
  const [type, setType] = useState<Pick<ShadowConditionClause, 'relation'>['relation']>(field.value.relation);
  const { t } = useTranslation();
  return (
    <>
      <Select
        defaultValue={options.find((opt) => opt.value === type)}
        label={t('shadow.conditions.select') as string}
        name={'condition'}
        className={'quarter'}
        options={options}
        onChange={(option) => {
          const opt = option as SingleValue<SelectOptionType>;
          setType(opt?.value as Pick<ShadowConditionClause, 'relation'>['relation']);
        }}
      />
      <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
        {type === 'HAS_GROUP' && <GroupsSelect name={name} />}
        {type === 'HAS_ROLE' && <RolesSelect name={name} />}
        {type === 'HAS_PROPERTY' && <PropertiesSelect name={name} />}
      </Suspense>
    </>
  );
};
