import { FieldArray, useFormikContext } from 'formik';
import { GroupAndProfileRole, ProfileFormType } from '../ProfileForm';
import { useTranslation } from 'react-i18next';
import React, { FC, useMemo, useState } from 'react';
import { PimProfileGroupsResponseType } from '../../graphql/types';
import { MultiValue, SingleValue } from 'react-select';
import { FormBlockHeader } from '../../../../ui/Form/FormBlockHeader';
import { AddIcon, Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { GroupRoles as GroupRoleEnum } from '@bp/pim-auth-constants';

export type GroupRolesProps = {
  groupList?: PimProfileGroupsResponseType[];
};

export const GroupRoles: FC<GroupRolesProps> = ({ groupList }) => {
  const { values, setFieldValue } = useFormikContext<ProfileFormType>();
  const { t } = useTranslation();
  const [selectedGroup, setSelectedGroup] = useState<PimProfileGroupsResponseType | null>();
  const [selectValue, setSelectValue] = useState<SingleValue<SelectOptionType> | undefined>();
  const groups = groupList
    ? [...groupList].filter((p) => values.roles.groups.filter((rp) => rp.uuid === p.uuid).length === 0)
    : [];

  const baseOptions: SelectOptionType[] = useMemo(() => {
    const roles: SelectOptionType[] = [];
    for (const [key, value] of Object.entries(GroupRoleEnum)) {
      roles.push({ value: value, label: key });
    }
    return roles;
  }, []);

  return (
    <div className={'form-block'}>
      <FormBlockHeader title={`Group roles`} />
      <FieldArray name={'roles.groups'}>
        {(arrayHelpers) => {
          return (
            <>
              {values.roles.groups.map((value, i) => {
                return (
                  <div className={'form-group'} key={i}>
                    <div className={'form-col'}>
                      <div className={'form-row'}>
                        {value.label}
                        <span className={'font-xs'}>{value.uuid}</span>
                      </div>
                      <div className={'form-row'}>
                        <Select
                          name={`roles.groups.${i}.values`}
                          options={baseOptions}
                          onChange={async (option) => {
                            const opt = option as MultiValue<SelectOptionType>;
                            await setFieldValue(
                              `roles.groups.${i}.values`,
                              opt.map((o) => o.value),
                            );
                          }}
                          value={value.values.map((v) => ({ value: v, label: v }))}
                          isClearable={true}
                          className={'full'}
                          placeholder={'Add roles'}
                        />
                        <Button
                          className={'align-self-center'}
                          hierarchy={'tertiary'}
                          icon={<DeleteIcon className={'svg-icon'} />}
                          onClick={() => arrayHelpers.remove(i)}
                        />
                      </div>
                    </div>
                    <div className={'form-col'}></div>
                  </div>
                );
              })}
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Select
                      value={selectValue}
                      className={'third'}
                      label={t('profiles.roles.groups.relation') as string}
                      name={'groupsList'}
                      options={groups?.map((p) => ({ value: p, label: p.name })) ?? []}
                      onChange={(option) => {
                        const data = option as SingleValue<SelectOptionType>;
                        const value = data?.value as PimProfileGroupsResponseType;
                        setSelectValue(data ?? undefined);
                        setSelectedGroup(value);
                      }}
                      isClearable={true}
                    />
                    <Button
                      className={'align-self-center'}
                      disabled={!selectedGroup}
                      icon={<AddIcon className={'svg-icon'} />}
                      onClick={() => {
                        setSelectValue(undefined);
                        arrayHelpers.push({
                          uuid: selectedGroup?.uuid,
                          label: selectedGroup?.name,
                          values: [],
                        } as GroupAndProfileRole);
                        setSelectValue(null);
                      }}
                    >
                      {t('profiles.roles.groups.add')}
                    </Button>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
