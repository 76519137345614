import { useTranslation } from 'react-i18next';
import styles from './Dashboard.module.css';
import { useEffect } from 'react';
import { Skeleton } from '@bp/ui-components';

export const Dashboard = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Dashboard`;
  }, []);

  return (
    <>
      <h1>{t('dashboard.title')}</h1>
      <div className={styles.wrapper}>
        <div className={styles.one}>
          <Skeleton />
        </div>
      </div>
    </>
  );
};
