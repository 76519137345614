import { FormBlockHeader } from '../../../../ui/Form/FormBlockHeader';
import { useFormikContext } from 'formik';
import { MultiValue } from 'react-select';
import React, { useMemo } from 'react';
import { ProfileFormType } from '../ProfileForm';
import { Select, SelectOptionType } from '@bp/ui-components';
import { AdminRoles, BpAdminRoles, PlanungAdminRoles, ProfileRoles } from '@bp/pim-auth-constants';

export const OrganizationRoles = () => {
  const { values, setFieldValue } = useFormikContext<ProfileFormType>();

  const baseOptions: SelectOptionType[] = useMemo(() => {
    const roles: SelectOptionType[] = [];
    for (const [key, value] of Object.entries(ProfileRoles)) {
      roles.push({ value: value, label: 'Person: ' + key });
    }
    for (const [key, value] of Object.entries(AdminRoles)) {
      roles.push({ value: value, label: 'Admin: ' + key });
    }
    for (const [key, value] of Object.entries(BpAdminRoles)) {
      roles.push({ value: value, label: 'Bp: ' + key });
    }
    for (const [key, value] of Object.entries(PlanungAdminRoles)) {
      roles.push({ value: value, label: 'Planung: ' + key });
    }
    return roles;
  }, []);

  return (
    <div className={'form-block'}>
      <FormBlockHeader title={`Organization role`} />
      <div className={'form-group'}>
        <div className={'form-col'}>
          <div className={'form-row'}>
            <Select
              name={`roles.organization`}
              options={baseOptions}
              isMulti={true}
              onChange={(option) => {
                const opt = option as MultiValue<SelectOptionType>;
                setFieldValue(
                  `roles.organization`,
                  opt.map((o) => o.value),
                );
              }}
              defaultValue={values.roles.organization.map((v) => ({ value: v, label: v }))}
              allowCreate={true}
              isClearable={true}
              className={'full'}
              placeholder={'Add roles'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
