import { Form, Formik, FormikHelpers } from 'formik';
import { FederatedClientResponse } from '../graphql/types';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'urql';
import {
  PimCreateFederatedClientsDocument,
  PimCreateFederatedClientsMutation,
  PimCreateFederatedClientsMutationVariables,
  PimUpdateFederatedClientsDocument,
  PimUpdateFederatedClientsMutation,
  PimUpdateFederatedClientsMutationVariables,
  usePimFederatedClientsQuery,
} from '../../../client/graphql-client-defs';
import { Button, Input, Select, SelectOptionType, showToast } from '@bp/ui-components';
import { MultiValue } from 'react-select';
import { v4 } from 'uuid';

type FederatedClientFormProps = {
  clientId: string | null;
  closeForm: () => void;
};

export const FederatedClientForm: FC<FederatedClientFormProps> = ({ clientId, closeForm }) => {
  const context = useMemo(() => ({ additionalTypenames: ['FederatedClients'] }), []);
  const { t } = useTranslation();

  const [{ data, fetching }] = usePimFederatedClientsQuery({
    variables: {
      where: { clientId: clientId ?? '' },
    },
    context,
  });

  const federatedClient: FederatedClientResponse = data?.federatedClients.find((c) => c.clientId === clientId) ?? {
    uuid: v4(),
    issuer: '',
    label: '',
    name: '',
    clientId: '',
    clientSecret: '',
    responseTypes: [],
  };

  const [, create] = useMutation<PimCreateFederatedClientsMutation, PimCreateFederatedClientsMutationVariables>(
    PimCreateFederatedClientsDocument,
  );
  const [, update] = useMutation<PimUpdateFederatedClientsMutation, PimUpdateFederatedClientsMutationVariables>(
    PimUpdateFederatedClientsDocument,
  );

  const handleSubmit = async (values: FederatedClientResponse, formHelpers: FormikHelpers<FederatedClientResponse>) => {
    const { __typename, clientId: id, ...data } = values;
    let result;
    if (clientId) {
      result = await update({ clientId: clientId, update: { ...data } }, context);
    } else {
      result = await create({ input: { clientId: id, ...data } }, context);
    }
    if (!result || result.error) {
      // sentry ?
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
    }
    formHelpers.resetForm();
    closeForm();
  };

  const responseTypesOptions: SelectOptionType[] = ['id_token', 'code'].map((r) => ({ value: r, label: r }));

  const responseTypesValues: SelectOptionType[] = federatedClient.responseTypes.map((responseType) => ({
    value: responseType,
    label: responseType,
  }));

  return (
    <Formik onSubmit={handleSubmit} initialValues={federatedClient}>
      {({ handleChange, values, isSubmitting, errors, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <div className={'form-block'}>
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Input
                      name={'issuer'}
                      onChange={handleChange}
                      value={values.issuer ?? ''}
                      label={t('federatedClients.issuer') as string}
                      className={'full'}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Input
                      name={'clientId'}
                      onChange={handleChange}
                      value={values.clientId ?? ''}
                      label={t('federatedClients.clientId') as string}
                      className={'half'}
                    />
                    <Input
                      name={'clientSecret'}
                      onChange={handleChange}
                      value={values.clientSecret ?? ''}
                      label={t('federatedClients.clientSecret') as string}
                      className={'half'}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Select
                      name={'responseTypes'}
                      label={t('federatedClients.responseTypes') as string}
                      placeholder={t('federatedClients.responseTypes') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={responseTypesOptions}
                      className={'full'}
                      defaultValue={responseTypesValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue(
                          'responseTypes',
                          opt.map((o) => o.value),
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'modal-bottom'}>
              <Button type='submit' disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
