import { Button, Card, Input, showToast } from '@bp/ui-components';
import {
  PimUpdatePasswordDocument,
  PimUpdatePasswordMutation,
  PimUpdatePasswordMutationVariables,
} from '../../../client/graphql-client-defs';
import { useMutation } from 'urql';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { validationSchema } from './validationSchema';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';

export type ProfilePasswordFormProps = {
  userUuid: string;
  afterSubmit: () => void;
};

export type PasswordUpdateFormType = {
  oldPassword?: string | null;
  newPassword: string;
  confirmNewPassword: string;
};

export const ProfilePasswordForm = ({ userUuid, afterSubmit }: ProfilePasswordFormProps) => {
  const [, updatePassword] = useMutation<PimUpdatePasswordMutation, PimUpdatePasswordMutationVariables>(
    PimUpdatePasswordDocument,
  );

  const permissionChecker = usePermissionChecker();

  const isAdmin = permissionChecker?.canUpdatePasswordWithoutOld({ uuid: userUuid });
  const { t } = useTranslation();
  const initialValues: PasswordUpdateFormType = { newPassword: '', oldPassword: null, confirmNewPassword: '' };
  const handleSubmit = async (values: PasswordUpdateFormType, formHelpers: FormikHelpers<PasswordUpdateFormType>) => {
    const props = isAdmin
      ? {
          newPassword: values.newPassword,
          userUuid,
        }
      : {
          newPassword: values.newPassword,
          oldPassword: values.oldPassword,
          userUuid,
        };

    const res = await updatePassword(props);
    if (res.error) {
      showToast(
        <>
          <h3>{res.error.name}</h3>
          <p>res.error.message</p>
        </>,
        {
          type: 'error',
        },
      );
    } else {
      showToast(
        <>
          <h3>Success</h3>
          <p>Password updated</p>
        </>,
        { type: 'success' },
      );
    }
    formHelpers.resetForm();
    afterSubmit();
  };
  return (
    <Card>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleChange, values, isSubmitting, errors, dirty, isValidating, isValid }) => {
          return (
            <Form>
              {!isAdmin && (
                <Input
                  name={'oldPassword'}
                  label={t('password.oldPasswordLabel') as string}
                  onChange={handleChange}
                  value={values.oldPassword ?? ''}
                  type={'password'}
                />
              )}
              <Input
                name={'newPassword'}
                label={t('password.newPasswordLabel') as string}
                onChange={handleChange}
                value={values.newPassword}
                type={'password'}
                error={errors.newPassword}
              />
              <Input
                name={'confirmNewPassword'}
                label={t('password.confirmNewPasswordLabel') as string}
                onChange={handleChange}
                value={values.confirmNewPassword}
                type={'password'}
                error={errors.confirmNewPassword}
              />
              <Button type={'submit'} disabled={isSubmitting || !isValid || !dirty || isValidating}>
                {t('change password')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};
