import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources } from './resources';

export const defaultNS = 'translation';

i18n.use(initReactI18next).init({
  fallbackLng: 'de',
  lng: 'de',
  defaultNS,
  resources,
  returnNull: false,
  returnObjects: false,
  supportedLngs: ['de', 'en', 'fr'],
  nonExplicitSupportedLngs: true,
});

// eslint-disable-next-line import/no-default-export
export default i18n;
