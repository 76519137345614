import { useAuth } from 'react-oidc-context';
import {
  isOmniOrganizationClaim,
  isOrganizationClaim,
  isOtherProfilesClaim,
  isProfileClaim,
  OMNI_ORGANIZATION_CLAIM,
  ORGANIZATION_CLAIM,
  OrganizationClaim,
  OTHER_PROFILES_CLAIM,
  OtherProfilesClaim,
  PROFILE_CLAIM,
  ProfileClaim,
  ROLES_CLAIM,
  RolesClaim,
  USER_CLAIM,
  UserClaim,
} from '@bp/pim-auth-constants';

const useAuthClaims = () => {
  const auth = useAuth();
  if (!auth.user) {
    throw new Error('useAuthClaims must be used within a component wrapped by AuthProvider');
  }

  const pimAuthClaims = {
    getOrganizationUuid: (): string => {
      const profile = auth.user?.profile[PROFILE_CLAIM] as ProfileClaim;
      if (!profile || !profile.organization) {
        throw new Error('Invalid user profile or missing organization');
      }
      return profile.organization.uuid;
    },
    getAllowedOrganizationUuids: (): string[] => {
      const organization = auth.user?.profile[ORGANIZATION_CLAIM];
      if (!organization || !isOrganizationClaim(organization)) {
        throw new Error('Invalid organization claim');
      }
      return [organization.uuid, ...organization.collaboratesWith.map((o) => o.uuid)];
    },
    getOmniOrganization: () => {
      const omniOrganization = auth.user?.profile[OMNI_ORGANIZATION_CLAIM];
      if (!omniOrganization || !isOmniOrganizationClaim(omniOrganization)) {
        throw new Error('Invalid or missing omni-organization');
      }
      return omniOrganization;
    },
    getOrganization: (): OrganizationClaim => {
      const organization = auth.user?.profile[ORGANIZATION_CLAIM];
      if (!organization || !isOrganizationClaim(organization)) {
        throw new Error('Invalid or missing organization');
      }
      return organization;
    },
    getOtherProfiles: (): OtherProfilesClaim => {
      const profile = auth.user?.profile[OTHER_PROFILES_CLAIM];
      if (!profile || !isOtherProfilesClaim(profile)) {
        throw new Error('Invalid or missing other-profiles');
      }
      return profile;
    },
    getProfile: (): ProfileClaim => {
      const profile = auth.user?.profile[PROFILE_CLAIM];
      if (!profile || !isProfileClaim(profile)) {
        throw new Error('Invalid or missing profile');
      }
      return profile;
    },
    getUser: (): UserClaim => {
      if (!auth || !auth.user) {
        throw new Error('No Auth User found');
      }
      return auth.user?.profile[USER_CLAIM] as UserClaim;
    },
    getRoles: (): RolesClaim => {
      const roles = auth.user?.profile[ROLES_CLAIM] as RolesClaim;
      if (!roles) {
        throw new Error('User roles information not available');
      }
      return roles;
    },
  };
  return { pimAuthClaims: pimAuthClaims };
};

export { useAuthClaims };
