import { FormBlockHeader } from '../../../ui/Form/FormBlockHeader';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, DeleteIcon, Input } from '@bp/ui-components';

export const PropertiesArrayField = <
  T extends {
    propertiesConnection: {
      edges: [{ properties: { scope: string }; node: { uuid: string; name: string; value: string } }];
    };
  },
>() => {
  const { values, handleChange } = useFormikContext<T>();
  const { t } = useTranslation();
  return (
    <div className={'form-block'}>
      <FormBlockHeader title={t('properties.title')} />
      <FieldArray name={'propertiesConnection.edges'}>
        {(arrayHelpers) => {
          return (
            <>
              {values.propertiesConnection.edges.map((propertiesConnection, index) => {
                return (
                  <div className={'form-group'} key={propertiesConnection.node.uuid}>
                    <div className={'form-col'}>
                      <div className={'form-row'}>
                        <Input
                          name={`propertiesConnection.edges.${index}.node.name`}
                          value={values.propertiesConnection.edges[index].node.name}
                          label={'Name'}
                          onChange={handleChange}
                          required={true}
                          className={'third'}
                        />
                        <Input
                          name={`propertiesConnection.edges.${index}.node.value`}
                          value={values.propertiesConnection.edges[index].node.value}
                          label={'Value'}
                          onChange={handleChange}
                          required={true}
                          className={'third'}
                        />
                        <Input
                          name={`propertiesConnection.edges.${index}.scope`}
                          value={values.propertiesConnection.edges[index].properties.scope}
                          label={'Scope'}
                          onChange={handleChange}
                          required={true}
                          className={'third'}
                        />
                      </div>
                    </div>
                    <div className={'form-col'}>
                      <Button icon={<DeleteIcon className={'svg-icon'} />} onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  </div>
                );
              })}
              <Button
                icon={<AddIcon className={'svg-icon'} />}
                onClick={() => arrayHelpers.push({ node: { uuid: '', name: '' } })}
              >
                {t('properties.add')}
              </Button>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};
