import { getTokens } from './authStore';

async function request<BpResponse>(path: string, config?: RequestInit): Promise<BpResponse> {
  const tokens = getTokens();

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: tokens ? `Bearer ${tokens.access_token}` : '',
  };
  if (config) {
    config.headers = { ...headers, ...config.headers };
  }
  const response = await fetch((process.env.REACT_APP_BP_BACKEND_URL as string) + `${path}`, config);
  return await response.json();
}
const bpApi = {
  get: <BpResponse>(path: string, headers?: HeadersInit) => request<BpResponse>(path, { method: 'GET', headers }),
  post: <TBody extends BodyInit, BpResponse>(path: string, body: TBody) =>
    request<BpResponse>(path, { method: 'POST', body }),
  put: <TBody extends BodyInit, BpResponse>(path: string, body: TBody) =>
    request<BpResponse>(path, { method: 'PUT', body }),
  delete: <BpResponse>(path: string, headers?: HeadersInit) => request<BpResponse>(path, { method: 'DELETE', headers }),
};
export { bpApi };
