import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
  useDefaultSorting,
} from '@bp/ui-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';
import { OrganizationForm } from './OrganizationForm/OrganizationForm';
import {
  PimDeleteOrganizationDocument,
  PimDeleteOrganizationMutation,
  PimDeleteOrganizationMutationVariables,
  SortDirection,
  usePimListOrganizationsQuery,
  usePimUpdateApplicationMutation,
} from '../../client/graphql-client-defs';
import { OrganizationZoomForm } from './OrganizationForm/OrganizationZoomForm';
import { useTranslation } from 'react-i18next';
import { ListOrganizationsType } from './graphql/types';
import { useConfirm } from '../../hooks/useConfirm';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { ApplicationClientIds } from '@bp/pim-auth-constants';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const Organizations = () => {
  const { t } = useTranslation();
  const { confirm, ConfirmationDialog } = useConfirm();

  const [currentOrganisationUuid, setCurrentOrganisationUuid] = useState('');
  const { sorting, onSortingChange } = useDefaultSorting();

  const [showForm, setShowForm] = useState(false);
  const [showZoomForm, setShowZoomForm] = useState(false);

  const [, updateApplication] = usePimUpdateApplicationMutation();

  const [, deleteOrganization] = useMutation<PimDeleteOrganizationMutation, PimDeleteOrganizationMutationVariables>(
    PimDeleteOrganizationDocument,
  );

  const context = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);

  const [{ data }, reloadOrganizations] = usePimListOrganizationsQuery({
    variables: {
      options: {
        sort: [{ name: SortDirection.Asc }],
      },
    },
    context,
  });

  const openForm = (row: Row<ListOrganizationsType>) => {
    const { __typename, ...rest } = row.original;
    setCurrentOrganisationUuid(rest.uuid);
    setShowForm(true);
  };

  const memoizedHandleDelete = useCallback(
    async (row: Row<ListOrganizationsType>) => {
      if (canDelete(row)) {
        await deleteOrganization(
          {
            where: {
              uuid: row.original.uuid,
            },
          },
          context,
        );
      }
    },
    [deleteOrganization, context],
  );

  function canDelete(row: Row<ListOrganizationsType>) {
    return (
      row.original.profilesConnection.totalCount === 0 &&
      row.original.groupsConnection.totalCount === 0 &&
      row.original.profilesConnection.totalCount === 0
    );
  }

  const tableColumns: TableColumns<ListOrganizationsType>[] = useMemo(() => {
    return [
      {
        header: 'Uuid',
        id: 'uuid',
        accessorKey: 'uuid',
        size: 150,
      },
      {
        header: 'Name',
        id: 'name',
        accessorKey: 'name',
        size: 250,
        canExpand: true,
      },
      {
        header: 'Authentication',
        id: 'authentication',
        accessorKey: 'authentication',
      },
      {
        header: 'Planung',
        id: 'planungEnabled',
        accessorKey: 'planungEnabled',
        type: 'boolean',
      },
      {
        header: 'BP',
        id: 'bpEnabled',
        accessorKey: 'bpEnabled',
        type: 'boolean',
      },
      {
        header: 'P!-Dashboard',
        id: 'procuratEnabled',
        accessorKey: 'procuratEnabled',
        type: 'boolean',
      },
      {
        header: 'IPHIS',
        id: 'iphisEnabled',
        accessorKey: 'iphisEnabled',
        type: 'boolean',
      },
      {
        header: 'Vplan',
        id: 'vplanEnabled',
        accessorFn: (row) => {
          if (row.vplanEnabled) return 'IPHIS';
          if (row.vpoEnabled) return 'VPO';

          return '-';
        },
        type: 'string',
        size: 50,
      },
      {
        header: 'Admin',
        id: 'adminEnabled',
        accessorKey: 'adminEnabled',
        type: 'boolean',
      },
    ];
  }, []);

  const tableData = useMemo((): ListOrganizationsType[] => {
    return data && data.organizations
      ? data.organizations.map((o) => {
          const validEdges = o.licensedApplicationsConnection.edges.filter((edge) => {
            return (
              dayjs((edge.properties.notBefore as string | null) ?? undefined).isSameOrAfter(dayjs(), 'day') &&
              dayjs((edge.properties.notAfter as string | null) ?? undefined).isSameOrBefore(dayjs(), 'day')
            );
          });
          return {
            ...o,
            planungEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Planung),
            bpEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Bp),
            adminEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Admin),
            procuratEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Procurat),
            iphisEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Iphis),
            vplanEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.IphisVplan),
            vpoEnabled: validEdges.some((a) => a.node.clientId === ApplicationClientIds.Vpo),
          };
        })
      : [];
  }, [data]);

  useEffect(() => {
    document.title = `Organizations`;
  }, []);

  const toggleApplication = async (organizationUuid: string, application: 'planung' | 'bp', status: boolean) => {
    const connectDisconnect = status ? 'connect' : 'disconnect';

    if (application === 'planung') {
      await updateApplication({
        where: { clientId: ApplicationClientIds.Planung },
        update: {
          licensedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }],
          accessGrantedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }],
        },
      });
    }
    if (application === 'bp') {
      await updateApplication({
        where: { clientId_IN: [ApplicationClientIds.Bp, ApplicationClientIds.BpApp] },
        update: {
          licensedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }],
          accessGrantedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }],
        },
      });
    }
  };

  const toggleVplan = async (organizationUuid: string, vplan: 'VPO' | 'Iphis', status: boolean) => {
    const connectDisconnect = status ? 'connect' : 'disconnect';
    if (vplan === 'VPO') {
      await updateApplication({
        where: { clientId: ApplicationClientIds.Vpo },
        update: { licensedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }] },
      });
      if (status) {
        await updateApplication({
          where: { clientId: ApplicationClientIds.IphisVplan },
          update: { licensedOrganizations: [{ disconnect: [{ where: { node: { uuid: organizationUuid } } }] }] },
        });
      }
    }
    if (vplan === 'Iphis') {
      await updateApplication({
        where: { clientId: ApplicationClientIds.IphisVplan },
        update: { licensedOrganizations: [{ [connectDisconnect]: [{ where: { node: { uuid: organizationUuid } } }] }] },
      });
      if (status) {
        await updateApplication({
          where: { clientId: ApplicationClientIds.Vpo },
          update: { licensedOrganizations: [{ disconnect: [{ where: { node: { uuid: organizationUuid } } }] }] },
        });
      }
    }
    reloadOrganizations({ requestPolicy: 'network-only' });
  };

  return (
    <div className='page'>
      <h2>{t('organizations.title')}</h2>
      <Table<ListOrganizationsType>
        showSelect={false}
        showActionBar
        showSort
        sorting={sorting}
        onSortingChange={onSortingChange}
        isOnWhite={false}
        actionBarSettings={{
          showAddButton: true,
          addButtonText: t('common.add', { type: 'Organisation' }) as string,
        }}
        onAddClick={() => {
          setShowForm(true);
        }}
        columns={tableColumns}
        data={tableData}
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                onClick={() => openForm(row)}
                icon={<EditIcon className={'svg-icon'} />}
              ></Button>
              <Dropdown
                trigger={
                  <Button isLoading={false} hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />
                }
              >
                <DropdownMenu
                  data={[
                    {
                      label: t('Planung aktivieren'),
                      disabled: row.original.planungEnabled,
                      onClick: async () => toggleApplication(row.original.uuid, 'planung', true),
                    },
                    {
                      label: t('Planung deaktivieren'),
                      disabled: !row.original.planungEnabled,
                      onClick: async () => toggleApplication(row.original.uuid, 'planung', false),
                    },
                    {
                      label: t('BP aktivieren'),
                      disabled: row.original.bpEnabled,
                      onClick: async () => toggleApplication(row.original.uuid, 'bp', true),
                    },
                    {
                      label: t('BP deaktivieren'),
                      disabled: !row.original.bpEnabled,
                      onClick: async () => toggleApplication(row.original.uuid, 'bp', false),
                    },
                    {
                      type: 'ruler',
                    },
                    {
                      label: t('VPO aktivieren'),
                      disabled: !row.original.planungEnabled || row.original.vpoEnabled,
                      onClick: async () => toggleVplan(row.original.uuid, 'VPO', true),
                    },
                    {
                      label: t('VPO deaktivieren'),
                      disabled: !row.original.planungEnabled || row.original.vplanEnabled || !row.original.vpoEnabled,
                      onClick: async () => toggleVplan(row.original.uuid, 'VPO', false),
                    },
                    {
                      label: t('IPHIS aktivieren'),
                      disabled: !row.original.planungEnabled || row.original.vplanEnabled,
                      onClick: async () => toggleVplan(row.original.uuid, 'Iphis', true),
                    },
                    {
                      label: t('IPHIS deaktivieren'),
                      disabled: !row.original.planungEnabled || row.original.vpoEnabled || !row.original.vplanEnabled,
                      onClick: async () => toggleVplan(row.original.uuid, 'Iphis', false),
                    },
                    {
                      type: 'ruler',
                    },
                    {
                      label: 'Organisation löschen',
                      color: 'error',
                      disabled: !canDelete(row),
                      onClick: async () => {
                        await confirm({
                          message: t('delete.confirm'),
                          onConfirm: async () => {
                            await memoizedHandleDelete(row);
                          },
                        });
                      },
                    },
                  ]}
                ></DropdownMenu>
              </Dropdown>
            </ButtonGroup>
          );
        }}
        lastColWidth={'100px'}
      />
      <Modal
        title={'Organizations Modal'}
        isOpen={showForm}
        onRequestClose={() => {
          setShowForm(false);
          setCurrentOrganisationUuid('');
        }}
      >
        <OrganizationForm
          uuid={currentOrganisationUuid}
          afterSubmit={() => {
            setShowForm(false);
            setCurrentOrganisationUuid('');
          }}
        />
      </Modal>
      <Modal
        title={'Organizations Zoom Data'}
        isOpen={showZoomForm}
        onRequestClose={() => {
          setShowZoomForm(false);
          setCurrentOrganisationUuid('');
        }}
      >
        <OrganizationZoomForm
          afterSubmit={() => {
            setShowZoomForm(false);
            setCurrentOrganisationUuid('');
          }}
          uuid={currentOrganisationUuid}
        ></OrganizationZoomForm>
      </Modal>
      <ConfirmationDialog />
    </div>
  );
};
