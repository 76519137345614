import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Modal, Row } from '@bp/ui-components';
import { ProfileForm } from './ProfileForm/ProfileForm';
import { OrganizationContext } from '../../context/OrganizationContext';
import { PimProfileTableType, ProfilesTable } from '../../ui/ProfilesTable/ProfilesTable';
import {
  usePimDeleteProfileMutation,
  usePimDeleteUserMutation,
  usePimListProfilesQuery,
} from '../../client/graphql-client-defs';
import { useTranslation } from 'react-i18next';

export const Profiles: FC = () => {
  const context = useMemo(() => ({ additionalTypenames: ['Profile'] }), []);
  const { organization } = useContext(OrganizationContext);

  const [, deleteProfile] = usePimDeleteProfileMutation();
  const [, deleteUser] = usePimDeleteUserMutation();

  const [openForm, setOpenForm] = useState(false);
  const [currentProfile, setCurrentProfile] = useState<PimProfileTableType | undefined>(undefined);

  const [{ data }] = usePimListProfilesQuery({
    context,
    variables: {
      where: {
        organization: {
          uuid: organization?.uuid,
        },
      },
    },
  });

  const handleEdit = (row: Row<PimProfileTableType>) => {
    setCurrentProfile(row.original);
    setOpenForm(true);
  };

  const memoizedHandleDelete = useCallback(
    (row: Row<PimProfileTableType>) => {
      void deleteProfile(
        {
          where: {
            uuid: row.original.uuid,
          },
        },
        context,
      );
    },
    [context, deleteProfile],
  );

  const memoizedHandleUserDelete = useCallback(
    (row: Row<PimProfileTableType>) => {
      if (row.original.user?.uuid !== undefined) {
        void deleteUser(
          {
            where: {
              uuid: row.original.user.uuid,
            },
          },
          context,
        );
      }
    },
    [context, deleteUser],
  );

  const tableData = useMemo(() => {
    return data && data.profiles ? data.profiles : [];
  }, [data]);

  const handleClose = () => {
    setOpenForm(false);
    setCurrentProfile(undefined);
  };

  const memoOrganizationZoom = useMemo(() => {
    if (organization) {
      return organization.properties.some((p) => {
        return p.name === 'zoom-enabled' && p.value === 'true';
      });
    }
    return false;
  }, [organization]);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Profiles - ${organization?.name ?? ''}`;
  }, [organization]);

  return (
    <div className={'page'}>
      {organization && (
        <>
          <h1>Profiles</h1>
          <ProfilesTable
            showSelect={true}
            showBulkEdit={true}
            showAddButton={true}
            onAdd={() => {
              setOpenForm(true);
            }}
            profiles={tableData}
            onDelete={memoizedHandleDelete}
            onUserDelete={memoizedHandleUserDelete}
            onEdit={handleEdit}
            onDeleteConfirmText={t('profiles.onDeleteConfirmText') as string}
            showZoomButton={memoOrganizationZoom}
            showSetPasswordButton={true}
          />
        </>
      )}
      {openForm && (
        <Modal
          title={(currentProfile ? t(`profiles.edit`) : t(`profiles.create`)) as string}
          isOpen={openForm}
          subtitle={currentProfile ? `ProfileUuid: ${currentProfile.uuid}, UserUuid: ${currentProfile.user?.uuid}` : ''}
          onRequestClose={handleClose}
        >
          {organization && <ProfileForm profileUuid={currentProfile?.uuid ?? null} afterSubmit={handleClose} />}
        </Modal>
      )}
    </div>
  );
};
