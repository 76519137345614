import React from 'react';
import i18nService from './core/i18n/i18n';
import * as Sentry from '@sentry/react';

// styles
import 'sanitize.css';
import './core/styles/flex.scss';
import './core/styles/form.scss';
import './core/styles/global.scss';
import './core/styles/grid.scss';
import './core/styles/modal.scss';
import './core/styles/styles.scss';
import './core/styles/table.scss';
import './core/styles/typography.scss';
import './core/styles/mixins/paddingMarginMixin.scss';

import { createRoot } from 'react-dom/client';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { App } from './App';

import i18n, { defaultNS } from './core/i18n/i18n';

// i18n
i18nService.init();

// Sentry instrumentation
if (import.meta.env.VITE_APP_SENTRY_DSN !== 'false') {
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    autoSessionTracking: true,
    release: 'pim-admin-frontend@' + import.meta.env.VITE_APP_VERSION,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container as Element);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
      <I18nextProvider i18n={i18n} defaultNS={defaultNS}>
        <BrowserRouter basename={import.meta.env.VITE_APP_BASEPATH}>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
