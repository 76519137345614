import { cacheExchange, createClient, errorExchange, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { clearTokens, getTokens } from './authStore';
import { UNAUTHORIZED_QUERY } from './constants';

export const urqlClient = createClient({
  url: import.meta.env.VITE_APP_BACKEND_URI + import.meta.env.VITE_APP_BACKEND_GRAPHQL_PATH || '',
  suspense: true,
  exchanges: [
    cacheExchange,
    authExchange(async (utils) => {
      return {
        addAuthToOperation(operation) {
          const tokens = getTokens();

          if (tokens) {
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${tokens.access_token}`,
            });
          }
          return operation;
        },
        willAuthError(_operation) {
          const tokens = getTokens();

          return !tokens;
        },
        didAuthError(error, _operation) {
          return error.graphQLErrors.some((e) => e.extensions?.code === UNAUTHORIZED_QUERY);
        },
        async refreshAuth() {
          const tokens = getTokens();

          if (!tokens?.access_token || (tokens?.expires_at && tokens?.expires_at <= Date.now())) {
            localStorage.clear();
          }
        },
      };
    }),
    errorExchange({
      onError: (error) => {
        const isAuthError =
          error.graphQLErrors.some((e) => e.extensions?.code === UNAUTHORIZED_QUERY) ||
          error.networkError?.message === 'Unauthorized';
        if (isAuthError) {
          clearTokens();
          window.location.href = '/';
        }
      },
    }),
    fetchExchange,
  ],
});
