import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Input } from '@bp/ui-components';
import {
  PimCreateOrganizationDocument,
  PimCreateOrganizationMutation,
  PimCreateOrganizationMutationVariables,
  PimListOrganizationsDocument,
  PimListOrganizationsQuery,
  PimListOrganizationsQueryVariables,
  PimUpdateOrganizationsDocument,
  PimUpdateOrganizationsMutation,
  PimUpdateOrganizationsMutationVariables,
} from '../../../client/graphql-client-defs';
import { useMutation, useQuery } from 'urql';
import { ListPlainOrganizationsType } from '../graphql/types';
import { PropertiesArrayField } from '../../Properties/PropertiesForm/PropertiesArrayField';

interface OrganisationFormProps {
  uuid?: string;
  afterSubmit: () => void;
}

export const OrganizationForm = ({ uuid, afterSubmit }: OrganisationFormProps) => {
  const context = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);
  const [, createOrganization] = useMutation<PimCreateOrganizationMutation, PimCreateOrganizationMutationVariables>(
    PimCreateOrganizationDocument,
  );
  const [, updateOrganization] = useMutation<PimUpdateOrganizationsMutation, PimUpdateOrganizationsMutationVariables>(
    PimUpdateOrganizationsDocument,
  );
  const [{ data, fetching }] = useQuery<PimListOrganizationsQuery, PimListOrganizationsQueryVariables>({
    query: PimListOrganizationsDocument,
    context,
    variables: {
      where: {
        uuid: uuid,
      },
      options: {
        limit: 1,
      },
    },
    pause: !uuid,
  });
  const initialValues: ListPlainOrganizationsType = {
    __typename: 'Organization',
    name: '',
    uuid: '',
    properties: [],
    groupsConnection: { __typename: 'OrganizationGroupsConnection', totalCount: 0 },
    profilesConnection: { __typename: 'OrganizationProfilesConnection', totalCount: 0 },
    propertiesConnection: { __typename: 'OrganizationPropertiesConnection', edges: [], totalCount: 0 },
    shadowsProfilesToConnection: { edges: [] },
    licensedApplicationsConnection: { edges: [] },
  };

  const handleSubmit = async (values: ListPlainOrganizationsType, formHelpers: FormikHelpers<ListPlainOrganizationsType>) => {
    if (values) {
      const { uuid, __typename, ...rest } = values;
      const { error } =
        uuid !== ''
          ? await updateOrganization({
              update: {
                name: rest.name,
              },
              where: {
                uuid: uuid,
              },
            })
          : await createOrganization({ input: { name: rest.name, timezone: 'Europe/Berlin', locale: 'de' } });
      if (!error) {
        afterSubmit();
        formHelpers.resetForm();
      }
    }
  };

  const organization: ListPlainOrganizationsType = data && data.organizations ? data.organizations[0] : initialValues;
  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={organization}>
        {({ handleChange, values, isSubmitting, errors }) => {
          return (
            <Form>
              <div className={'form-block'}>
                <div className={'form-group'}>
                  <div className={'form-col'}>
                    <div className={'form-row'}>
                      <Input
                        name={'name'}
                        className={'full'}
                        value={values.name}
                        label={'Name'}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <PropertiesArrayField />

              <div className={'modal-bottom'}>
                <Button
                  type='submit'
                  disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
