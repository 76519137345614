import { FC, PropsWithChildren } from 'react';

type FormBlockHeaderTypes = PropsWithChildren & {
  title: string;
  subTitle?: string;
};

export const FormBlockHeader: FC<FormBlockHeaderTypes> = ({ title, subTitle, children }: FormBlockHeaderTypes) => {
  return (
    <div className={'form-block-header'}>
      <div className={'form-block-header__content'}>
        <h3>
          {title} {subTitle}
        </h3>
        <div className={'form-block-header__children'}>{children}</div>
      </div>
      <span className={'form-block-header__border'}>&nbsp;</span>
    </div>
  );
};
