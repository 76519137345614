import React, { useContext, useMemo, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, DatePicker, Input, Select } from '@bp/ui-components';
import { connectByUuid, rolesConnect } from '../../../utils/connectLib';
import { SelectOptionType } from '@bp/ui-components/dist/@bp/lib/Select/Select';
import {
  Group,
  PimCreateProfileDocument,
  PimCreateProfileMutation,
  PimCreateProfileMutationVariables,
  PimCreateUserWithProfileDocument,
  PimCreateUserWithProfileMutation,
  PimCreateUserWithProfileMutationVariables,
  PimProfileFormDocument,
  PimProfileFormQuery,
  PimProfileFormQueryVariables,
  PimUpdateProfileDocument,
  PimUpdateProfileMutation,
  PimUpdateProfileMutationVariables,
  Profile,
} from '../../../client/graphql-client-defs';
import { useMutation, useQuery } from 'urql';
import { OrganizationContext } from '../../../context/OrganizationContext';
import { PropertiesArrayField } from '../../Properties/PropertiesForm/PropertiesArrayField';
import { PimProfileResponseType } from '../graphql/types';
import { OrganizationRoles } from './Roles/OrganizationRoles';
import { ProfileRoles } from './Roles/ProfileRoles';
import { GroupRoles } from './Roles/GroupRoles';

interface ProfileFormProps {
  profileUuid: string | null;
  afterSubmit: () => void;
}

export type GroupAndProfileRole = { uuid: string; label: string; values: string[] };

export type Roles = {
  organization: string[];
  profiles: GroupAndProfileRole[];
  groups: GroupAndProfileRole[];
};

export type ProfileFormType = {
  uuid?: string;
  propertiesConnection: {
    edges: Array<{
      properties: { scope: string };
      node: { value: string; name: string; uuid: string };
    }>;
  };
  roles: Roles;
  user: { uuid: string };
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  address3: string;
  email: string;
  birthday: string;
};

export const ProfileForm = ({ profileUuid, afterSubmit }: ProfileFormProps) => {
  const context = useMemo(() => ({ additionalTypenames: ['Profile'] }), []);
  const [, createProfile] = useMutation<PimCreateProfileMutation, PimCreateProfileMutationVariables>(
    PimCreateProfileDocument,
  );
  const [, updateProfile] = useMutation<PimUpdateProfileMutation, PimUpdateProfileMutationVariables>(
    PimUpdateProfileDocument,
  );
  const [, createUserAndProfile] = useMutation<
    PimCreateUserWithProfileMutation,
    PimCreateUserWithProfileMutationVariables
  >(PimCreateUserWithProfileDocument);
  const { organization } = useContext(OrganizationContext);
  const [userUuid, setUserUuid] = useState<string | null>(null);

  const [{ data, fetching }] = useQuery<PimProfileFormQuery, PimProfileFormQueryVariables>({
    query: PimProfileFormDocument,
    context,
    variables: {
      where: {
        uuid: profileUuid,
      },
      options: {
        limit: 1,
      },
      organizationUuid: organization?.uuid,
      currentProfileUuid: profileUuid,
    },
  });
  const getInitialValues = (data: PimProfileResponseType | undefined): ProfileFormType => {
    if (!data) {
      return {
        propertiesConnection: { edges: [] },
        roles: {
          profiles: [],
          organization: [],
          groups: [],
        },
        uuid: '',
        user: { uuid: '' },
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        address3: '',
        email: '',
        birthday: '',
      };
    }
    return {
      propertiesConnection: { edges: data.propertiesConnection.edges },
      roles: {
        profiles: data.rolesConnection.edges
          .filter((e) => e.node.__typename === 'Profile')
          .map((e) => {
            const node = e.node as Profile;
            return {
              uuid: node.uuid,
              values: e.properties.names,
              label: `${node?.lastName}, ${node?.firstName}`,
            };
          }),
        groups: data.rolesConnection.edges
          .filter((e) => e.node.__typename === 'Group')
          .map((e) => {
            const node = e.node as Group;
            return {
              uuid: node.uuid,
              values: e.properties.names,
              label: node.name,
            };
          }),
        organization: data.rolesConnection.edges
          .filter((e) => e.node.__typename === 'Organization')
          .map((e) => {
            return e.properties.names;
          })
          .flat(),
      },
      uuid: data.uuid,
      user: { uuid: data.user?.uuid ?? '' },
      firstName: data.firstName ?? '',
      lastName: data.lastName ?? '',
      address1: data.address1 ?? '',
      address2: data.address2 ?? '',
      address3: data.address3 ?? '',
      email: data.email ?? '',
      birthday: data.birthday ?? '',
    };
  };

  const profile: ProfileFormType = getInitialValues(data?.profile[0]);

  const profilesFromOtherOrganizations = data && data.profilesFromOtherOrganizations;
  const createSelectList = (): SelectOptionType[] | undefined => {
    return profilesFromOtherOrganizations?.map((profile) => {
      return {
        label: `Uuid: ${profile.user?.uuid}, Email: ${profile.email} - Profile Name: ${profile.firstName} ${profile.lastName} - Organization: ${profile.organization.name} `,
        value: profile.user?.uuid,
      } as SelectOptionType;
    });
  };

  const selectOptions = useMemo(createSelectList, [profilesFromOtherOrganizations]);

  const handleSubmit = async (values: ProfileFormType, formHelpers: FormikHelpers<ProfileFormType>) => {
    const { uuid, user, ...rest } = values;
    const { error } =
      uuid !== ''
        ? await updateProfile(
            {
              update: {
                firstName: rest.firstName,
                lastName: rest.lastName,
                address1: rest.address1,
                address2: rest.address2,
                address3: rest.address3,
                email: rest.email,
                birthday: rest.birthday,
                user: connectByUuid(userUuid),
                roles: {
                  Organization: [
                    {
                      disconnect: [{}],
                      connect: [
                        {
                          where: { node: { uuid: organization?.uuid } },
                          edge: { names: values.roles.organization },
                        },
                      ],
                    },
                  ],
                  Profile: [
                    {
                      disconnect: [{}],
                      connect: values.roles.profiles.map((p) => rolesConnect(p.uuid, p.values)),
                    },
                  ],
                  Group: [
                    {
                      disconnect: [{}],
                      connect: values.roles.groups.map((p) => rolesConnect(p.uuid, p.values)),
                    },
                  ],
                },
                properties: [
                  {
                    delete: [{}],
                    create: values.propertiesConnection.edges.map((prop) => ({
                      edge: { scope: prop.properties.scope },
                      node: { name: prop.node.name, value: prop.node.value },
                    })),
                  },
                ],
              },
              where: {
                uuid: uuid,
              },
            },
            context,
          )
        : userUuid
          ? await createProfile(
              {
                input: {
                  firstName: rest.firstName,
                  lastName: rest.lastName,
                  address1: rest.address1,
                  address2: rest.address2,
                  address3: rest.address3,
                  email: rest.email,
                  birthday: rest.birthday,
                  properties: {
                    create: values.propertiesConnection.edges.map((prop) => {
                      return {
                        edge: { scope: prop.properties.scope },
                        node: { name: prop.node.name, value: prop.node.value },
                      };
                    }),
                  },
                  roles: {
                    Organization: {
                      connect: [rolesConnect(organization?.uuid ?? '', values.roles.organization)],
                    },
                    Profile: {
                      connect: values.roles.profiles.map((p) => rolesConnect(p.uuid, p.values)),
                    },
                    Group: {
                      connect: values.roles.groups.map((p) => rolesConnect(p.uuid, p.values)),
                    },
                  },
                  organization: connectByUuid(organization?.uuid ?? null),
                  ...(userUuid && {
                    user: connectByUuid(userUuid),
                  }),
                },
              },
              context,
            )
          : await createUserAndProfile(
              {
                input: {
                  email: rest.email,
                  emailVerified: false,
                  profiles: {
                    create: [
                      {
                        node: {
                          properties: {
                            create: values.propertiesConnection.edges.map((prop) => {
                              return {
                                edge: { scope: prop.properties.scope },
                                node: { name: prop.node.name, value: prop.node.value },
                              };
                            }),
                          },
                          roles: {
                            Organization: {
                              connect: [
                                {
                                  where: { node: { uuid: organization?.uuid } },
                                  edge: { names: values.roles.organization },
                                },
                              ],
                            },
                            Profile: {
                              connect: values.roles.profiles.map((p) => rolesConnect(p.uuid, p.values)),
                            },
                            Group: {
                              connect: values.roles.groups.map((p) => rolesConnect(p.uuid, p.values)),
                            },
                          },
                          firstName: rest.firstName,
                          lastName: rest.lastName,
                          address1: rest.address1,
                          address2: rest.address2,
                          address3: rest.address3,
                          email: rest.email,
                          birthday: rest.birthday,
                          organization: connectByUuid(organization?.uuid ?? null),
                        },
                      },
                    ],
                  },
                },
              },
              context,
            );

    if (!error) {
      afterSubmit();
      formHelpers.resetForm();
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={profile}>
      {({ handleChange, values, isSubmitting, errors, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <div className={'form-block'}>
              <div className={'form-col'}>
                <div className={'form-row'}>
                  <Input
                    name={'firstName'}
                    value={values.firstName as string}
                    label={'First Name'}
                    onChange={handleChange}
                    required={true}
                    className={'half'}
                  />
                  <Input
                    name={'lastName'}
                    value={values.lastName as string}
                    label={'Last Name'}
                    onChange={handleChange}
                    required={true}
                    className={'half'}
                  />
                </div>
                <div className={'form-row'}>
                  <Input
                    name={'email'}
                    value={values.email as string}
                    label={'E-Mail'}
                    onChange={handleChange}
                    className={'half'}
                  />
                  <DatePicker
                    className={'half'}
                    name={'birthday'}
                    locale={'de'}
                    value={values.birthday ? new Date(values.birthday) : undefined}
                    label={'Birthday'}
                    onChange={async (date) => {
                      await setFieldValue('birthday', date);
                    }}
                    onBlur={async () => {
                      await setFieldTouched('birthday', true);
                    }}
                  />
                </div>
                <div className={'form-row'}>
                  <Input
                    name={'address1'}
                    value={values.address1 as string}
                    label={'Address 1'}
                    onChange={handleChange}
                    className={'third'}
                  />
                  <Input
                    name={'address2'}
                    value={values.address2 as string}
                    label={'Address 2'}
                    onChange={handleChange}
                    className={'third'}
                  />
                  <Input
                    name={'address3'}
                    value={values.address3 as string}
                    label={'Address 3'}
                    onChange={handleChange}
                    className={'third'}
                  />
                </div>
                <div className={'form-row'}>
                  {selectOptions && (
                    <Select
                      className={'full'}
                      label={'User'}
                      name={'user'}
                      value={{
                        value: profile.user.uuid,
                        label: `Email: ${profile.email} - Profile Name: ${profile.firstName} ${profile.lastName} - Organization: ${organization?.name} `,
                      }}
                      options={selectOptions}
                      {...(profile &&
                        profile.user?.uuid && {
                          defaultValue: {
                            value: profile.user?.uuid,
                            label: `Email: ${profile.email} - Profile Name: ${profile.firstName} ${profile.lastName} - Organization: ${organization?.name} `,
                          },
                        })}
                      onChange={(event) => {
                        !event ? setUserUuid(null) : setUserUuid((event as SelectOptionType).value as string);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <PropertiesArrayField />
            <OrganizationRoles />
            <ProfileRoles profileList={data?.list} />
            <GroupRoles groupList={data?.groups} />
            <div className={'modal-bottom'}>
              <Button type='submit' disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
