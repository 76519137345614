import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Logo, Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './AboutModule.module.scss';

export type AboutModalProps = {
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
};

type PimHealth = {
  name?: string;
  version?: string;
  status: string;
  ok: boolean;
  neo4j?: {
    name?: string;
    ok: boolean;
    status?: string;
    message?: string;
  };
};
type ApolloHealth = {
  status: string;
  ok: boolean;
  version?: string;
};

export const AboutModal = (props: AboutModalProps) => {
  const { t } = useTranslation();
  const [pimGraphHealth, setPimGraphHealth] = useState<ApolloHealth | null>({
    status: 'ERROR',
    ok: false,
  });
  const [pimHealth, setPimHealth] = useState<PimHealth | null>({
    status: 'ERROR',
    ok: false,
  });

  useEffect(() => {
    fetch(import.meta.env.VITE_APP_BACKEND_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setPimGraphHealth(data))
      .catch(() => {
        setPimGraphHealth({ status: 'ERROR', ok: false });
      });

    fetch(import.meta.env.VITE_APP_AUTH_SERVER_URI + '/_health')
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw resp;
      })
      .then((data) => setPimHealth(data))
      .catch(() => {
        setPimHealth({ status: 'ERROR', ok: false });
      });
  }, []);

  let buildTime: string | undefined = import.meta.env.VITE_APP_BUILD_TIME;
  if (buildTime === undefined) {
    buildTime = 'hmr/live';
  }

  return (
    <Modal
      className={styles['about-modal']}
      title={t('app.title') as string}
      subtitle={(APP_VERSION ?? 'dev') + '/' + import.meta.env.VITE_APP_ENVIRONMENT + ' (' + buildTime + ')'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={props.state}
      onRequestClose={() => props.setState(false)}
      hideFooter={false}
      footer={
        <>
          <div className={styles.logo}>
            <Logo type={'tk'} collapsed={false} />
          </div>
          <div className={styles.firma}>
            TK-Schulsoftware GmbH & Co. KG
            <br />
            Rosenwiesstr. 17
            <br />
            70567 Stuttgart
            <br />
          </div>
          <div className={styles.spacer}>&nbsp;</div>
          <div className={styles.version}>
            <div className={styles.product}>TK-Login (Version: {pimHealth?.version ?? 'unknown'})</div>
            <div className={styles.status}>{pimHealth?.status === 'green' ? 'Online' : 'Offline'}</div>

            <div className={styles.product}>Server (Version: {pimGraphHealth?.version ?? 'unknown'})</div>
            <div className={styles.status}>{pimGraphHealth?.status === 'green' ? 'Online' : 'Offline'}</div>
          </div>
        </>
      }
    >
      <div className={styles.main}>
        <div>
          Falls Sie Hilfe benötigen, dürfen Sie sich gerne an uns wenden:
          <br />
          <br />
          <span>+49 (0) 711 – 50 87 00 00</span>
          <br />
          <span>support@tks.eu</span>
        </div>
      </div>
    </Modal>
  );
};
