import { useMutation } from 'urql';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { FederatedClientResponse } from './graphql/types';
import { useTranslation } from 'react-i18next';
import { PimDeleteFederatedClientsDocument, usePimFederatedClientsQuery } from '../../client/graphql-client-defs';
import { FederatedClientForm } from './Form/FederatedClientForm';

export const FederatedClients = () => {
  const { t } = useTranslation();

  const [{ data }] = usePimFederatedClientsQuery({});

  const [, deleteAuthClient] = useMutation(PimDeleteFederatedClientsDocument);

  const [openForm, setOpenForm] = useState(false);
  const [currentAuthClient, setCurrentAuthClient] = useState<string | null>(null);

  const createColumns = useMemo((): TableColumns<FederatedClientResponse>[] => {
    return [
      {
        header: t('federatedClients.clientId') as string,
        accessorKey: 'clientId',
        id: 'clientId',
        type: 'string',
        size: 250,
      },
      {
        header: t('federatedClients.issuer') as string,
        accessorKey: 'issuer',
        id: 'issuer',
        canExpand: true,
        canOverflow: false,
      },
    ];
  }, [t]);

  const tableData = useMemo(() => {
    return data?.federatedClients ?? [];
  }, [data]);

  const handleClose = () => {
    setOpenForm(false);
    setCurrentAuthClient(null);
  };

  const handleEdit = (row: Row<FederatedClientResponse>) => {
    setCurrentAuthClient(row.original.clientId ?? '');
    setOpenForm(true);
  };

  const handleDelete = (row: Row<FederatedClientResponse>) => {
    deleteAuthClient({ clientId: row.original.clientId });
  };
  useEffect(() => {
    document.title = `AuthClients`;
  }, []);

  return (
    <div className='page'>
      <Table<FederatedClientResponse>
        showActionBar
        isOnWhite={false}
        actionBarSettings={{ showAddButton: true }}
        data={tableData}
        columns={createColumns}
        onAddClick={() => {
          setOpenForm(true);
        }}
        lastCol={(row) => (
          <ButtonGroup>
            <Button
              hierarchy='secondary'
              onClick={() => handleEdit(row)}
              icon={<EditIcon className={'svg-icon'} />}
            ></Button>
            <AlertDialog
              title={t('delete.title')}
              confirmText={t('delete.confirmText')}
              cancelText={t('delete.abort')}
              onConfirm={() => handleDelete(row)}
              trigger={<Button hierarchy='secondary' icon={<DeleteIcon className={'svg-icon'} />} />}
            >
              <p>{t('delete.confirmText')}</p>
            </AlertDialog>
          </ButtonGroup>
        )}
      />
      {openForm && (
        <Modal
          isOpen={openForm}
          onRequestClose={handleClose}
          title={currentAuthClient ? (t('authClients.edit') as string) : (t('authClients.create') as string)}
          subtitle={currentAuthClient ?? ''}
        >
          <FederatedClientForm closeForm={handleClose} clientId={currentAuthClient}></FederatedClientForm>
        </Modal>
      )}
    </div>
  );
};
