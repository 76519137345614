import { createContext } from 'react';
import { ListPlainOrganizationsType } from '../routes/Organizations/graphql/types';

export type OrganizationContextType = {
  organization: ListPlainOrganizationsType | null;
  setOrganization: (organization: ListPlainOrganizationsType | null) => void;
};

export const OrganizationContext = createContext<OrganizationContextType>({
  organization: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setOrganization: () => {},
});
export const OrganizationContextProvider = OrganizationContext.Provider;
export const OrganizationContextConsumer = OrganizationContext.Consumer;
