import React, { useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
  useDefaultSorting,
  VisibilityState,
} from '@bp/ui-components';
import { ProfilePasswordForm } from '../../routes/Profiles/ProfileForm/ProfilePasswordForm';
import { useTranslation } from 'react-i18next';
import { PimProfileQueryType } from '../../routes/Profiles/graphql/types';
import { usePimApplicationsQuery, usePimUpdateApplicationMutation } from '../../client/graphql-client-defs';
import { useConfirm } from '../../hooks/useConfirm';
import { ApplicationClientIds } from '@bp/pim-auth-constants';

export type PimProfileTableType = {
  uuid: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  birthday?: string | null;
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  user?: { __typename?: 'User'; uuid: string } | null;
  organization: { __typename?: 'Organization'; uuid: string; name: string };
  properties: Array<{ __typename?: 'Property'; uuid: string; name: string; value: string }>;
  rolesConnection?: {
    __typename?: 'ProfileRolesConnection';
    edges: Array<{
      __typename?: 'ProfileRolesRelationship';
      properties: { __typename?: 'RoleNames'; names: Array<string> };
      node:
        | { __typename?: 'Group'; uuid: string; name: string }
        | { __typename?: 'Organization'; uuid: string; name: string }
        | { __typename?: 'Profile'; uuid: string; firstName: string; lastName: string }
        | { __typename?: 'User' };
    }>;
  };
  planungEnabled: boolean;
  bpEnabled: boolean;
  procuratEnabled: boolean;
  iphisEnabled: boolean;
  adminEnabled: boolean;
};

export type UserTableProps = {
  profiles: PimProfileQueryType[];
  showAddButton: boolean;
  onAdd?: () => void;
  onEdit?: (row: Row<PimProfileTableType>) => void;
  onDelete?: (row: Row<PimProfileTableType>) => void;
  onUserDelete?: (row: Row<PimProfileTableType>) => void;
  onDeleteConfirmText?: string | ((row: Row<PimProfileTableType>) => string);
  showSelect: boolean;
  showBulkEdit: boolean;
  showZoomButton?: boolean;
  showSetPasswordButton?: boolean;
};

export const ProfilesTable = ({
  profiles,
  onEdit,
  onDelete,
  onUserDelete,
  onAdd,
  showBulkEdit = false,
  showSelect = false,
  showSetPasswordButton = false,
}: UserTableProps) => {
  const [passwordModalProfile, setPasswordModalProfile] = useState<PimProfileTableType | null>(null);
  const { t } = useTranslation();
  const tableColumns: TableColumns<PimProfileTableType>[] = useMemo(() => {
    return [
      {
        id: 'uuid',
        header: 'Profile Uuid',
        accessorKey: 'uuid',
        size: 90,
      },
      {
        id: 'user',
        header: 'User Uuid',
        accessorKey: 'user',
        accessorFn: (row) => {
          return row.user?.uuid ?? '';
        },
        size: 90,
      },
      {
        id: 'firstName',
        header: 'First Name',
        accessorKey: 'firstName',
        size: 150,
      },
      {
        header: 'Last Name',
        accessorKey: 'lastName',
        id: 'lastName',
        size: 150,
        canExpand: true,
      },
      {
        header: 'E-Mail',
        accessorKey: 'email',
        id: 'email',
        size: 150,
        canExpand: true,
      },
      {
        header: 'Birthday',
        accessorKey: 'birthday',
        id: 'birthday',
        cell: (cell) => {
          if (!cell.row.original.birthday) {
            return <></>;
          }
          const date = new Date(cell.row.original.birthday as string);
          return <>{date.toLocaleDateString('de-DE')}</>;
        },
      },
      {
        header: 'Address 1',
        accessorKey: 'address1',
        id: 'address1',
      },
      {
        header: 'Zoom User',
        accessorKey: 'properties',
        accessorFn: (row) => {
          row.properties.some((prop) => {
            return prop.name === 'zoom-user' && prop.value === 'true';
          });
        },
        id: 'properties',
        type: 'boolean',
      },
      {
        header: 'Roles',
        id: 'roles',
        accessorFn: (row) => {
          return row.rolesConnection?.edges
            .filter((e) => e.node.__typename === 'Organization')
            .flatMap((e) => e.properties.names);
        },
        type: 'string',
        size: 300,
      },
      {
        header: 'Planung',
        id: 'planungEnabled',
        accessorKey: 'planungEnabled',
        type: 'boolean',
      },
      {
        header: 'P!-Dashboard',
        id: 'procuratEnabled',
        accessorKey: 'procuratEnabled',
        type: 'boolean',
      },
      {
        header: 'IPHIS',
        id: 'iphisEnabled',
        accessorKey: 'iphisEnabled',
        type: 'boolean',
      },
      {
        header: 'Admin',
        id: 'adminEnabled',
        accessorKey: 'adminEnabled',
        type: 'boolean',
      },
    ];
  }, []);

  const { confirm, ConfirmationDialog } = useConfirm();
  const { sorting, onSortingChange } = useDefaultSorting();

  const [{ data: applicationData }, reloadApplicationData] = usePimApplicationsQuery({
    variables: {},
  });
  const [, updateApplication] = usePimUpdateApplicationMutation();

  const tableData = useMemo((): PimProfileTableType[] => {
    return profiles.map((p) => ({
      ...p,
      planungEnabled: !!applicationData?.applications.some(
        (a) =>
          a.clientId === 'planung-frontend-client' &&
          a.accessGrantedProfiles.some((profile) => profile.uuid === p.uuid),
      ),
      bpEnabled: !!applicationData?.applications.some(
        (a) =>
          a.clientId === 'bp-frontend-client' &&
          a.accessGrantedOrganizations.some((org) => org.uuid === p.organization.uuid),
      ),
      adminEnabled: !!applicationData?.applications.some(
        (a) =>
          a.clientId === 'admin-frontend-client' && a.accessGrantedProfiles.some((profile) => profile.uuid === p.uuid),
      ),
      procuratEnabled: !!applicationData?.applications.some(
        (a) =>
          a.clientId === 'procurat-dashboard-client' &&
          a.accessGrantedProfiles.some((profile) => profile.uuid === p.uuid),
      ),
      iphisEnabled: !!applicationData?.applications.some(
        (a) => a.clientId === 'iphis-client' && a.accessGrantedProfiles.some((profile) => profile.uuid === p.uuid),
      ),
    }));
  }, [profiles, applicationData?.applications]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    uuid: true,
    firstName: true,
    lastName: true,
    email: true,
    birthday: false,
    address1: false,
    user: true,
    properties: false,
    procuratEnabled: false,
    iphisEnabled: false,
  });

  function handleDelete(row: Row<PimProfileTableType>) {
    return confirm({
      message: t('delete.confirm'),
      onConfirm: async () => {
        onDelete && onDelete(row);
      },
    });
  }

  async function handleGrantPlanungAccess(profile: PimProfileTableType, grant: boolean = true) {
    if (grant) {
      await updateApplication({
        where: {
          clientId: ApplicationClientIds.Planung,
        },
        update: {
          accessGrantedProfiles: [{ connect: [{ overwrite: true, where: { node: { uuid: profile.uuid } } }] }],
        },
      });
    } else {
      await updateApplication({
        where: { clientId: ApplicationClientIds.Planung },
        update: {
          accessGrantedProfiles: [{ disconnect: [{ where: { node: { uuid: profile.uuid } } }] }],
        },
      });
    }
    reloadApplicationData({ requestPolicy: 'network-only' });
  }

  async function handleUserDelete(row: Row<PimProfileTableType>) {
    return confirm({
      message: t('delete.confirm'),
      onConfirm: async () => {
        onUserDelete && onUserDelete(row);
      },
    });
  }

  return (
    <div style={{ margin: '1em 0' }}>
      <Table<PimProfileTableType>
        key={'pim-profiles'}
        onAddClick={() => {
          if (onAdd) {
            onAdd();
          }
        }}
        showActionBar
        showSort
        sorting={sorting}
        onSortingChange={onSortingChange}
        isOnWhite={false}
        actionBarSettings={{
          showAddButton: true,
          addButtonText: 'Add Profile',
          showBulkEdit: showBulkEdit,
          showFilteredRowCount: true,
        }}
        showSelect={showSelect}
        showVisibility
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={setColumnVisibility}
        bulkEditDropdownContent={(selectedFlatRows) => {
          return (
            <Button
              hierarchy={'tertiary'}
              onClick={() => {
                selectedFlatRows.forEach((_row) => {
                  //addZoomUser(row as unknown as Row<Profile>);
                });
              }}
            >
              Create <b>{selectedFlatRows.length}</b> Zoom Profile{selectedFlatRows.length > 1 ? 's' : ''}
            </Button>
          );
        }}
        columns={tableColumns}
        data={tableData}
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                disabled={!onEdit}
                onClick={() => onEdit && onEdit(row)}
                icon={<EditIcon className={'svg-icon'} />}
              />
              <Dropdown
                trigger={
                  <Button isLoading={false} hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />
                }
              >
                <DropdownMenu
                  data={[
                    {
                      label: t('change password'),
                      disabled: !showSetPasswordButton || row.original.user?.uuid === undefined,
                      onClick: () => {
                        setPasswordModalProfile(row.original);
                      },
                    },
                    {
                      type: 'ruler',
                    },
                    {
                      label: t('Planung aktivieren'),
                      disabled: row.original.user?.uuid === undefined,
                      onClick: async () => {
                        await handleGrantPlanungAccess(row.original, true);
                      },
                    },
                    {
                      label: t('Planung deaktivieren'),
                      disabled: row.original.user?.uuid === undefined,
                      onClick: async () => {
                        await handleGrantPlanungAccess(row.original, false);
                      },
                    },
                    {
                      type: 'ruler',
                    },
                    {
                      label: 'Profil löschen',
                      color: 'error',
                      disabled: row.original.user?.uuid !== undefined,
                      onClick: async () => {
                        await handleDelete(row);
                      },
                    },
                    {
                      label: 'Benutzer löschen',
                      color: 'error',
                      disabled: row.original.user?.uuid === undefined,
                      onClick: async () => {
                        await handleUserDelete(row);
                      },
                    },
                  ]}
                />
              </Dropdown>
            </ButtonGroup>
          );
        }}
        lastColWidth={'80px'}
      />
      {passwordModalProfile && passwordModalProfile.user && (
        <Modal
          title={'Password Modal'}
          isOpen={!!passwordModalProfile}
          onRequestClose={() => {
            setPasswordModalProfile(null);
          }}
        >
          <ProfilePasswordForm
            afterSubmit={() => setPasswordModalProfile(null)}
            userUuid={passwordModalProfile.user.uuid}
          ></ProfilePasswordForm>
        </Modal>
      )}
      <ConfirmationDialog />
    </div>
  );
};
