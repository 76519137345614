import { useContext, useMemo } from 'react';
import { useMutation } from 'urql';
import { InputMaybe, OrganizationUpdateInput, PimUpdateOrganizationsDocument } from '../client/graphql-client-defs';
import { OrganizationContext } from '../context/OrganizationContext';

export function usePimUpdateOrganization({ organizationUuid }: { organizationUuid: string }) {
  const context = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { organization, setOrganization } = useContext(OrganizationContext);

  const [, update] = useMutation(PimUpdateOrganizationsDocument);
  return async (organizationUpdateInput: InputMaybe<OrganizationUpdateInput>) => {
    const { data } = await update({ where: { uuid: organizationUuid }, update: organizationUpdateInput }, context);
    if (data && data.updateOrganizations) {
      setOrganization(data.updateOrganizations.organizations[0]);
    }
  };
}
