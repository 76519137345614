import { ProfileRolesProfileConnectFieldInput } from '../client/graphql-client-defs';

export const connectByUuid = (uuid: string | null) => {
  return {
    connect: {
      where: {
        node: {
          uuid,
        },
      },
    },
  };
};

export const connectOrCreateByUuid = (uuid: string) => {
  return {
    connectOrCreate: {
      where: {
        node: {
          uuid,
        },
      },
      onCreate: {
        node: {
          uuid,
        },
      },
    },
  };
};

export const rolesConnect = (uuid: string, names: string[]): ProfileRolesProfileConnectFieldInput => {
  return {
    edge: {
      names: names,
    },
    where: { node: { uuid: uuid } },
  };
};
