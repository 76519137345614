import React, { forwardRef, PropsWithChildren } from 'react';
import { Button, ProfileIcon, Tooltip } from '@bp/ui-components';
import { useAuthClaims } from '../../hooks/useAuthClaims';

interface IconProps {
  onClick?: () => void;
}

// eslint-disable-next-line react/prop-types
export const UserIcon = forwardRef<HTMLButtonElement, PropsWithChildren<IconProps>>(({ onClick, ...rest }, ref) => {
  const { pimAuthClaims } = useAuthClaims();
  const name = pimAuthClaims.getProfile()?.firstName + ' ' + pimAuthClaims.getProfile()?.lastName;

  return (
    <Tooltip content={name}>
      <Button hierarchy='tertiary' icon={<ProfileIcon className={'svg-icon'} />} onClick={onClick} ref={ref}>
        {name}
      </Button>
    </Tooltip>
  );
});

UserIcon.displayName = 'UserIcon';
