import React, { CSSProperties, FC, useMemo } from 'react';
import { Select } from '@bp/ui-components';
import { SelectOptionType } from '@bp/ui-components/dist/@bp/lib/Select/Select';
import { MultiValue, SingleValue } from 'react-select';
import { usePimListOrganizationsQuery } from '../../client/graphql-client-defs';
import styles from './OrganizationSelect.module.sass';
import { useCreateSelectOptions } from '../../hooks/useCreateSelectOptions';
import { ListPlainOrganizationsType } from '../../routes/Organizations/graphql/types';
import { useTranslation } from 'react-i18next';
import { SortDirection } from '@bp/pim-graphql-types';

export interface OrganizationSelectProps {
  onChange: (organization: ListPlainOrganizationsType | null) => void;
  defaultValue?: SelectOptionType | undefined;
  emptyLabel?: string;
  style?: CSSProperties;
}

export const OrganizationSelect: FC<OrganizationSelectProps> = ({ onChange, defaultValue, emptyLabel = '', style }) => {
  const context = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);
  const { t } = useTranslation();
  const [{ data }] = usePimListOrganizationsQuery({
    variables: {
      options: { sort: [{ name: SortDirection.Asc }] },
    },
    context,
  });

  const options: SelectOptionType[] = useCreateSelectOptions(data?.organizations, 'uuid', 'name');

  /*
  Find selected organization by uuid in query result and pass it to onChange
   */
  function handleChange(event: MultiValue<SelectOptionType> | SingleValue<SelectOptionType>) {
    const currentSelected = data?.organizations.find((item) => {
      return item.uuid === (event as SelectOptionType).value;
    });
    onChange(currentSelected ?? null);
  }

  return (
    <div className={styles.wrapper}>
      <Select
        className={styles.select}
        name={'organizations'}
        options={options}
        defaultValue={defaultValue}
        isMulti={false}
        isSearchable
        onChange={handleChange}
        placeholder={t('organizationSelect.placeholder') as string}
      />
    </div>
  );
};
