import { FC, useContext, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import {
  PimRolesInOrganizationDocument,
  PimRolesInOrganizationQuery,
  PimRolesInOrganizationQueryVariables,
} from '../../../../client/graphql-client-defs';
import { Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useField, useFormikContext } from 'formik';
import { ShadowProfileFromType } from '../ShadowProfilesForm/ShadowProfilesForm';
import { useTranslation } from 'react-i18next';
import { HasRoleClause, RoleTargetType } from '@bp/pim-shadow-conditions';
import { OrganizationContext } from '../../../../context/OrganizationContext';

type RolesSelectType = {
  name: string;
};

export const RolesSelect: FC<RolesSelectType> = ({ name }) => {
  const context = useMemo(() => ({ additionalTypenames: ['Roles'] }), []);
  const formik = useFormikContext<ShadowProfileFromType>();
  const [field] = useField<HasRoleClause | null>(name);
  const { t } = useTranslation();
  const { organization } = useContext(OrganizationContext);
  const [selectedTarget, setSelectedTarget] = useState<RoleTargetType | null>(field?.value?.target?.type ?? null);

  const [{ data }] = useQuery<PimRolesInOrganizationQuery, PimRolesInOrganizationQueryVariables>({
    query: PimRolesInOrganizationDocument,
    variables: {
      organizationUuid: organization?.uuid ?? '',
    },
    context,
  });

  const options: { [k in RoleTargetType]: SelectOptionType[] } = useMemo(() => {
    const result: { [k in RoleTargetType]: SelectOptionType[] } = {
      Organization: [],
      Group: [],
      Profile: [],
      User: [],
    };
    if (!data) return result;

    data.organizations.forEach((organization) => {
      const incomingRolesConnections = [
        organization.incomingRolesConnection,
        ...organization.groups.map((group) => group.incomingRolesConnection),
        ...organization.profiles.map((profile) => profile.incomingRolesConnection),
      ];
      incomingRolesConnections.forEach((connection, index) => {
        connection.edges.forEach((edge) => {
          edge.properties.names.forEach((name) => {
            if (edge.__typename === 'GroupIncomingRolesRelationship') {
              const value: Pick<HasRoleClause, 'target'>['target'] = { type: 'Group', uuid: edge.node.uuid };
              result.Group.push({ label: name, value: value });
            } else if (edge.__typename === 'ProfileIncomingRolesRelationship') {
              const value: Pick<HasRoleClause, 'target'>['target'] = { type: 'Profile', uuid: edge.node.uuid };
              result.Profile.push({ label: name, value: value });
            } else if (edge.__typename === 'OrganizationIncomingRolesRelationship') {
              const value: Pick<HasRoleClause, 'target'>['target'] = { type: 'Organization', uuid: edge.node.uuid };
              result.Organization.push({ label: name, value: value });
            }
          });
        });
      });
    });
    return result;
  }, [data]);

  const roleTargetsOptions: SelectOptionType[] = useMemo(() => {
    const roleTargets: RoleTargetType[] = ['Organization', 'User', 'Group', 'Profile'];
    return roleTargets.map((value) => ({ value: value, label: value }));
  }, []);
  console.log(field);
  return (
    <>
      <Select
        className={'quarter'}
        label={t('roles.type') as string}
        name={'roleTargets'}
        allowCreate={true}
        defaultValue={roleTargetsOptions.find((roleTarget) => roleTarget.value === selectedTarget)}
        options={roleTargetsOptions}
        onChange={(options) => {
          const o = options as SingleValue<SelectOptionType>;
          setSelectedTarget(o?.value as RoleTargetType);
          formik.setFieldValue(name, {});
        }}
      />
      {selectedTarget === 'Organization' && (
        <Select
          defaultValue={{ label: field.value?.name ?? '', value: field.value?.target }}
          className={'quarter'}
          label={t('roles.title') as string}
          name={name}
          options={options.Organization}
          onChange={(options) => {
            const o = options as SingleValue<SelectOptionType>;
            formik.setFieldValue(name, { relation: 'HAS_ROLE', name: o?.label, target: o?.value });
          }}
        />
      )}
      {selectedTarget === 'Group' && (
        <Select
          defaultValue={{ label: field.value?.name ?? '', value: field.value?.target }}
          className={'quarter'}
          label={t('roles.title') as string}
          name={name}
          options={options.Group}
          onChange={(options) => {
            const o = options as SingleValue<SelectOptionType>;
            formik.setFieldValue(name, { relation: 'HAS_ROLE', name: o?.label, target: o?.value });
          }}
        />
      )}
      {selectedTarget === 'Profile' && (
        <Select
          defaultValue={{ label: field.value?.name ?? '', value: field.value?.target }}
          className={'quarter'}
          label={t('roles.title') as string}
          name={name}
          options={options.Profile}
          onChange={(options) => {
            const o = options as SingleValue<SelectOptionType>;
            formik.setFieldValue(name, { relation: 'HAS_ROLE', name: o?.label, target: o?.value });
          }}
        />
      )}
    </>
  );
};
