import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { AddIcon, Button, Card, Checkbox, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import {
  PimListOrganizationsDocument,
  PimListOrganizationsQuery,
  PimListOrganizationsQueryVariables,
  SortDirection,
} from '../../../../client/graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { ListPlainOrganizationsType } from '../../../Organizations/graphql/types';
import styles from './ShadowProfilesForm.module.scss';
import classNames from 'classnames';
import { ConditionsForm } from '../RelationForm/ConditionsForm';
import { FormBlockHeader } from '../../../../ui/Form/FormBlockHeader';
import {
  HasGroupClause,
  HasPropertyClause,
  HasRoleClause,
  parseShadowConditions,
  ShadowConditions,
} from '@bp/pim-shadow-conditions';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { usePimUpdateOrganization } from '../../../../hooks/usePimUpdateOrganization';
import { useQuery } from 'urql';

type ShadowProfileFromItemType = {
  orConditions: Array<{ andConditions: Array<HasGroupClause | HasRoleClause | HasPropertyClause> }>;
  optIn?: boolean;
  targetOrganization: ListPlainOrganizationsType | null;
};

export type ShadowProfileFromType = ShadowProfileFromItemType;

type Props = {
  onClose: () => void;
  incomingOrganizationUuid: string | null;
};

export const ShadowProfilesForm: FC<Props> = ({ onClose, incomingOrganizationUuid }) => {
  const [selectedOrganization, setSelectedOrganization] = useState<ListPlainOrganizationsType | null>(null);
  const context = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);
  const { t } = useTranslation();
  const { organization: currentOrganization } = useContext(OrganizationContext);
  const currentOrganizationUuid = currentOrganization?.uuid;

  const updateOrganization = usePimUpdateOrganization({
    organizationUuid: currentOrganizationUuid ?? '',
  });

  const currentData = currentOrganization?.shadowsProfilesToConnection.edges
    .filter((edge) => edge.node.uuid === selectedOrganization?.uuid)
    .map((edge) => {
      const conditions = parseShadowConditions(edge.properties.conditions);
      return {
        optIn: edge.properties.optIn,
        targetOrganization: selectedOrganization,
        orConditions: conditions.map((andConditions) => {
          return { andConditions: andConditions };
        }),
      } as ShadowProfileFromItemType;
    });

  const initialValues: ShadowProfileFromType = {
    optIn: currentData && currentData.length >= 1 ? currentData[0].optIn : false,
    orConditions:
      currentData && currentData.length >= 1
        ? currentData[0].orConditions
        : [{ andConditions: [{} as HasGroupClause] }],
    targetOrganization: selectedOrganization,
  };
  const handleSubmit = async (values: ShadowProfileFromType, formHelpers: FormikHelpers<ShadowProfileFromType>) => {
    let hasConditions = false;
    const conditions: ShadowConditions = values.orConditions.map((condition) => {
      return condition.andConditions.map((and) => {
        if (and.relation) {
          hasConditions = true;
        }
        return and;
      });
    });

    if (conditions.length > 0 && hasConditions) {
      await updateOrganization({
        shadowsProfilesTo: [
          {
            disconnect: [{}],
            connect: [
              {
                where: { node: { uuid: values.targetOrganization?.uuid } },
                edge: { optIn: values.optIn ?? false, conditions: JSON.stringify(conditions) },
              },
            ],
          },
        ],
      });
    } else {
      await updateOrganization({
        shadowsProfilesTo: [
          {
            disconnect: [{}],
          },
        ],
      });
    }
    formHelpers.resetForm();
    onClose();
  };
  const [{ data }] = useQuery<PimListOrganizationsQuery, PimListOrganizationsQueryVariables>({
    query: PimListOrganizationsDocument,
    variables: {
      options: {
        sort: [{ name: SortDirection.Asc }],
      },
    },
    context,
  });

  const organizations = data?.organizations;

  useEffect(() => {
    if (incomingOrganizationUuid) {
      const current = organizations?.find(({ uuid }) => incomingOrganizationUuid === uuid);
      setSelectedOrganization(current ?? null);
    }
  }, [organizations, incomingOrganizationUuid]);

  const organizationOptions: SelectOptionType[] = useMemo(() => {
    return (
      organizations
        ?.filter((o) => {
          return o.uuid !== currentOrganizationUuid;
        })
        .map((o) => ({ value: o, label: o.name })) ?? []
    );
  }, [currentOrganizationUuid, organizations]);

  return (
    <>
      {selectedOrganization ? (
        <Formik<ShadowProfileFromType> onSubmit={handleSubmit} initialValues={initialValues} enableReinitialize={true}>
          {({ setFieldTouched, setFieldValue, values, isSubmitting, errors }) => {
            return (
              <Form>
                <>
                  <div className={'form-block'}>
                    <FormBlockHeader
                      title={t('shadow.shadowProfilesTo', { organization: values.targetOrganization?.name })}
                      subTitle={values.targetOrganization?.uuid ?? ''}
                    ></FormBlockHeader>
                    <div className={'form-group'} key={values.targetOrganization?.uuid}>
                      <div className={'form-col'}>
                        <div className={'form-row'}>
                          <Checkbox
                            label={t('shadow.optIn') as string}
                            name={`optIn`}
                            checked={values.optIn ?? false}
                            onChange={(event) => {
                              setFieldValue(`optIn`, event.target.checked);
                              setFieldTouched(`optIn`);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <FieldArray name={`orConditions`}>
                      {(orArrayHelpers) => {
                        return (
                          <>
                            {values.orConditions.map((orConnected, orConnectedIndex) => {
                              return (
                                <div key={orConnectedIndex}>
                                  <FieldArray name={`orConditions.${orConnectedIndex}.andConditions`}>
                                    {(andArrayHelpers) => {
                                      return (
                                        <>
                                          <Card className={styles['bg-grey-lightest']}>
                                            <div className={'form-group'}>
                                              <div className={classNames('form-col', 'form-col-actions')}>
                                                <div className={'form-row'}>
                                                  <Button
                                                    onClick={() => orArrayHelpers.remove(orConnectedIndex)}
                                                    hierarchy={'tertiary'}
                                                    icon={<DeleteIcon className={'svg-icon small'} />}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            {orConnected.andConditions.map((andConnected, andConnectedIndex) => {
                                              return (
                                                <Card
                                                  key={andConnectedIndex}
                                                  className={classNames(styles['border-grey-lighter'], 'form-group')}
                                                >
                                                  <div className={'form-col'}>
                                                    <div className={'form-row'}>
                                                      <ConditionsForm
                                                        name={`orConditions.${orConnectedIndex}.andConditions.${andConnectedIndex}`}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className={classNames('form-col', 'form-col-actions')}>
                                                    <div className={'form-row'}>
                                                      <Button
                                                        onClick={() => andArrayHelpers.remove(andConnectedIndex)}
                                                        hierarchy={'tertiary'}
                                                        icon={<DeleteIcon className={'svg-icon small'} />}
                                                      >
                                                        {t('shadow.remove')}
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </Card>
                                              );
                                            })}
                                            <Button
                                              className={'align-self-center'}
                                              icon={<AddIcon className={'svg-icon'} />}
                                              onClick={() => {
                                                andArrayHelpers.push({});
                                              }}
                                            >
                                              {t('shadow.and')}
                                            </Button>
                                          </Card>
                                        </>
                                      );
                                    }}
                                  </FieldArray>
                                  {orConnectedIndex < values.orConditions.length - 1 && (
                                    <div className={styles.middle}>{t('shadow.or')}</div>
                                  )}
                                </div>
                              );
                            })}
                            <div className={styles.middle}>
                              <Button
                                icon={<AddIcon className={'svg-icon'} />}
                                onClick={() => {
                                  orArrayHelpers.push({
                                    andConditions: [{ relation: 'HAS_GROUP' }],
                                  });
                                }}
                              >
                                {t('shadow.or')}
                              </Button>
                            </div>
                          </>
                        );
                      }}
                    </FieldArray>
                  </div>
                </>
                <div className={'modal-bottom'}>
                  <Button type='submit' disabled={isSubmitting || (errors && Object.values(errors).length > 0)}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div className={'form-block'}>
          <FormBlockHeader title={t('shadow.profiles.add-organization')} subTitle={''}></FormBlockHeader>
          <div className={'form-group'}>
            <div className={'form-col'}>
              <div className={'form-row'}>
                <Select
                  className={'third'}
                  name={'groupsList'}
                  options={organizationOptions}
                  onChange={(option) => {
                    const data = option as SingleValue<SelectOptionType>;
                    const value = data?.value as ListPlainOrganizationsType;
                    setSelectedOrganization(value);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
