import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  ProfileIcon,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { OrganizationContext } from '../../context/OrganizationContext';
import { GroupsUserForm } from './GroupForm/GroupsUserForm';
import { GroupsForm } from './GroupForm/GroupsForm';
import {
  PimDeleteGroupDocument,
  PimDeleteGroupMutation,
  PimDeleteGroupMutationVariables,
  PimListGroupsDocument,
  PimListGroupsQuery,
  PimListGroupsQueryVariables,
} from '../../client/graphql-client-defs';
import { ListGroups } from './graphql/types';
import { useTranslation } from 'react-i18next';

export const Groups: FC = () => {
  const { organization } = useContext(OrganizationContext);
  const context = useMemo(() => ({ additionalTypenames: ['Group'] }), []);

  const [, deleteGroup] = useMutation<PimDeleteGroupMutation, PimDeleteGroupMutationVariables>(PimDeleteGroupDocument);

  const [openUserForm, setOpenAddUserForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const [currentGroupUuid, setCurrentGroupUuid] = useState<string>('');
  const [currentGroupName, setCurrentGroupName] = useState<string>('');

  const [{ data }] = useQuery<PimListGroupsQuery, PimListGroupsQueryVariables>({
    query: PimListGroupsDocument,
    context,
    variables: {
      where: {
        organization: {
          uuid: organization?.uuid,
        },
      },
      organizationUuid: organization?.uuid,
    },
  });

  const handleEdit = (row: Row<ListGroups>, userForm = false) => {
    const { __typename, ...rest } = row.original;
    setCurrentGroupUuid(rest.uuid);
    setCurrentGroupName(rest.name);
    userForm ? setOpenAddUserForm(true) : setOpenForm(true);
  };

  const createColumns = (): TableColumns<ListGroups>[] => {
    return [
      {
        header: 'Name',
        id: 'name',
        accessorKey: 'name',
      },
      { header: 'Member', id: 'profilesConnection.totalCount', accessorKey: 'profilesConnection.totalCount' },
    ];
  };

  const memoizedHandleDelete = useCallback(
    (row: Row<ListGroups>) => {
      deleteGroup(
        {
          where: {
            uuid: row.original.uuid,
          },
        },
        context,
      );
    },
    [context, deleteGroup],
  );

  const tableColumns = useMemo(createColumns, []);
  const tableData: ListGroups[] = useMemo((): ListGroups[] => {
    return data && data.groups ? data.groups : [];
  }, [data]);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = `Groups - ${organization?.name ?? ''}`;
  }, [organization]);

  return (
    <div className={'page'}>
      <h1>{t('groups.title')}</h1>
      {organization && (
        <Table
          showSelect={false}
          showActionBar
          isOnWhite={false}
          actionBarSettings={{
            showAddButton: true,
          }}
          onAddClick={() => {
            setOpenForm(true);
          }}
          columns={tableColumns}
          data={tableData}
          lastCol={(row) => (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                onClick={() => handleEdit(row)}
                icon={<EditIcon className={'svg-icon'} />}
              ></Button>
              <Button
                hierarchy='secondary'
                onClick={() => handleEdit(row, true)}
                icon={<ProfileIcon className={'svg-icon'} />}
              >
                <span className={'button-text-fix'}> {t('groups.editUsers')}</span>
              </Button>
              <AlertDialog
                title={t('delete.title')}
                confirmText={t('delete.confirmText')}
                cancelText={t('delete.abort')}
                onConfirm={() => memoizedHandleDelete(row)}
                trigger={<Button hierarchy='secondary' icon={<DeleteIcon className={'svg-icon'} />} />}
              >
                <p>{t('delete.confirmText')}</p>
              </AlertDialog>
            </ButtonGroup>
          )}
        />
      )}
      {organization && (
        <>
          {openUserForm && (
            <Modal
              title={t('groups.editUsers') + ': ' + currentGroupName}
              isOpen={openUserForm}
              onRequestClose={() => setOpenAddUserForm(false)}
            >
              <GroupsUserForm groupUuid={currentGroupUuid} organization={organization} />
            </Modal>
          )}
          {openForm && (
            <Modal
              title={t('groups.edit') + ': ' + currentGroupName}
              isOpen={openForm}
              onRequestClose={() => {
                setOpenForm(false);
                setCurrentGroupUuid('');
                setCurrentGroupName('');
              }}
            >
              <>
                <GroupsForm
                  groupUuid={currentGroupUuid}
                  afterSubmit={(error) => {
                    if (!error) {
                      setOpenForm(false);
                      setCurrentGroupUuid('');
                      setCurrentGroupName('');
                    } else {
                      console.log(error.message);
                    }
                  }}
                />
              </>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};
