import React, { FC, useState } from 'react';
import styles from './App.module.scss';
import { Button, Card, InfoIcon, Logo } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { AboutModal } from './ui/AboutModal/AboutModal';
import { usePermissionChecker } from './hooks/usePermissionChecker';
import { useAuth } from 'react-oidc-context';
import { useAuthClaims } from './hooks/useAuthClaims';

type RequireAdminProps = {
  children?: React.ReactNode;
};

export const RequireAdmin: FC<RequireAdminProps> = ({ children }) => {
  const auth = useAuth();
  const { pimAuthClaims } = useAuthClaims();
  const permissionChecker = usePermissionChecker();
  const { t } = useTranslation();
  const [showAbout, setShowAbout] = useState<boolean>(false);

  if (permissionChecker?.isOmniAdmin() || process.env.REACT_APP_CYPRESS_TEST === 'true') {
    return <>{children}</>;
  }
  return (
    <div className={styles.loginwrapper} data-cy='layoutWrapper'>
      <header>
        <Button
          hierarchy={'tertiary'}
          icon={<InfoIcon className={'svg-icon'} />}
          onClick={() => setShowAbout(true)}
        ></Button>
      </header>
      <AboutModal state={showAbout} setState={setShowAbout}></AboutModal>
      <main className={styles.main}>
        <div className={styles.container}>
          <Card>
            <div className={styles.logo}>
              <Logo type={'admin'} collapsed={false} />
            </div>
            <div className={styles.vspace}>{t('acl.not-omni')}</div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => auth.signoutRedirect()} fullWidth={true}>
                {t('auth.other-account')}
              </Button>
            </div>
            <div className={styles.loginseparator}>{t('auth.change-profile')}</div>
            <div className={styles.vspace}>
              {pimAuthClaims.getOtherProfiles()?.map((profile, _key) => {
                return (
                  <Button
                    key={_key}
                    hierarchy='secondary'
                    onClick={() => auth.signinSilent({ prompt: 'consent', login_hint: profile.uuid })}
                    fullWidth={true}
                    disabled={true}
                    title={t('not-implemented') as string}
                  >
                    {profile.firstName} {profile.lastName} {profile.organization.name}
                  </Button>
                );
              })}
            </div>
          </Card>
        </div>
      </main>
      <footer>Impressum | Datenschutz | Nutzungsbedingungen | Partner</footer>
    </div>
  );
};
