import { Form, Formik, FormikHelpers } from 'formik';
import { AuthClientResponse } from '../graphql/types';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import {
  PimAuthClientDocument,
  PimCreateAuthClientsDocument,
  PimCreateAuthClientsMutation,
  PimCreateAuthClientsMutationVariables,
  PimUpdateAuthClientsDocument,
  PimUpdateAuthClientsMutation,
  PimUpdateAuthClientsMutationVariables,
} from '../../../client/graphql-client-defs';
import { MultiValue } from 'react-select';
import { Button, Input, Select, SelectOptionType, showToast } from '@bp/ui-components';
import {
  CHILD_PROFILES_SCOPE,
  CLOUD_SCOPE,
  OMNI_ORGANIZATION_SCOPE,
  OTHER_PROFILES_SCOPE,
  PROFILE_SCOPE,
  ROLES_SCOPE,
  USER_SCOPE,
} from '@bp/pim-auth-constants';
import { v4 } from 'uuid';

type AuthClientFormProps = {
  clientId: string | null;
  closeForm: () => void;
};

export const AuthClientForm: FC<AuthClientFormProps> = ({ clientId, closeForm }) => {
  const context = useMemo(() => ({ additionalTypenames: ['AuthClients'] }), []);
  const { t } = useTranslation();

  const [{ data, fetching }] = useQuery({
    query: PimAuthClientDocument,
    variables: {
      clientId: clientId ?? '',
    },
    context,
  });

  const authClient: AuthClientResponse =
    clientId && data && data.authClients
      ? data?.authClients[0]
      : {
          clientId: v4(),
          clientSecret: v4(),
          scope: '',
          tokenEndpointAuthMethod: 'none',
          grantTypes: [],
          responseTypes: [],
          postLogoutRedirectUris: [],
          redirectUris: [],
        };

  const [, create] = useMutation<PimCreateAuthClientsMutation, PimCreateAuthClientsMutationVariables>(
    PimCreateAuthClientsDocument,
  );
  const [, update] = useMutation<PimUpdateAuthClientsMutation, PimUpdateAuthClientsMutationVariables>(
    PimUpdateAuthClientsDocument,
  );

  const handleSubmit = async (values: AuthClientResponse, formHelpers: FormikHelpers<AuthClientResponse>) => {
    const { __typename, clientId: id, clientName: name, tksExtensionConsentOptional, ...data } = values;
    let result;
    if (clientId) {
      result = await update({ clientId: clientId, update: { ...data } }, context);
    } else {
      result = await create({ input: { clientId: id, ...data } }, context);
    }
    if (!result || result.error) {
      // sentry ?
      showToast('Ups.', { type: 'error' });
    } else {
      showToast('Success', { type: 'success' });
    }
    formHelpers.resetForm();
    closeForm();
  };

  const scopesOptions: SelectOptionType[] = [
    'openid',
    'offline_access',
    'email',
    PROFILE_SCOPE,
    ROLES_SCOPE,
    USER_SCOPE,
    OTHER_PROFILES_SCOPE,
    CHILD_PROFILES_SCOPE,
    CLOUD_SCOPE,
    OMNI_ORGANIZATION_SCOPE,
  ].map((s) => ({ value: s, label: s }));

  const scopesValues: SelectOptionType[] = authClient.scope
    ? authClient.scope.split(' ').map((s) => ({ value: s, label: s }))
    : [];

  const grantTypesOptions: SelectOptionType[] = ['refresh_token', 'authorization_code'].map((g) => ({
    value: g,
    label: g,
  }));

  const grantTypesValues: SelectOptionType[] = authClient.grantTypes.map((grantType) => ({
    value: grantType,
    label: grantType,
  }));

  const responseTypesOptions: SelectOptionType[] = ['id_token', 'code'].map((r) => ({ value: r, label: r }));

  const responseTypesValues: SelectOptionType[] = authClient.responseTypes.map((responseType) => ({
    value: responseType,
    label: responseType,
  }));

  const postLogoutRedirectUrisValues: SelectOptionType[] =
    authClient.postLogoutRedirectUris?.map((postLogoutRedirectUris) => ({
      value: postLogoutRedirectUris,
      label: postLogoutRedirectUris,
    })) ?? [];

  const redirectUrisValues: SelectOptionType[] =
    authClient.redirectUris?.map((redirectUri) => ({
      value: redirectUri,
      label: redirectUri,
    })) ?? [];

  return (
    <Formik onSubmit={handleSubmit} initialValues={authClient}>
      {({ handleChange, values, isSubmitting, errors, setFieldValue, setFieldTouched }) => {
        return (
          <Form>
            <div className={'form-block'}>
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Input
                      name={'clientName'}
                      onChange={handleChange}
                      value={values.clientName ?? ''}
                      label={t('authClients.clientName') as string}
                      className={'full'}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Input
                      name={'clientId'}
                      onChange={handleChange}
                      value={values.clientId}
                      label={t('authClients.clientId') as string}
                      className={'half'}
                    />
                    <Input
                      name={'clientSecret'}
                      onChange={handleChange}
                      value={values.clientSecret ?? ''}
                      label={t('authClients.clientSecret') as string}
                      className={'half'}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Input
                      name={'tokenEndpointAuthMethod'}
                      onChange={handleChange}
                      value={values.tokenEndpointAuthMethod}
                      label={t('authClients.tokenEndpointAuthMethod') as string}
                      className={'half'}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Select
                      name={'scope'}
                      label={t('authClients.scope') as string}
                      placeholder={t('authClients.scope') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={scopesOptions}
                      className={'full'}
                      defaultValue={scopesValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue('scope', opt.map((o) => o.value).join(' '));
                      }}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Select
                      name={'grantTypes'}
                      label={t('authClients.grantTypes') as string}
                      placeholder={t('authClients.grantTypes') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={grantTypesOptions}
                      className={'half'}
                      defaultValue={grantTypesValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue(
                          'grantTypes',
                          opt.map((o) => o.value),
                        );
                      }}
                    />
                    <Select
                      name={'responseTypes'}
                      label={t('authClients.responseTypes') as string}
                      placeholder={t('authClients.responseTypes') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={responseTypesOptions}
                      className={'half'}
                      defaultValue={responseTypesValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue(
                          'responseTypes',
                          opt.map((o) => o.value),
                        );
                      }}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Select
                      name={'postLogoutRedirectUris'}
                      label={t('authClients.postLogoutRedirectUris') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={[]}
                      placeholder={t('authClients.postLogoutRedirectUris') as string}
                      className={'full'}
                      defaultValue={postLogoutRedirectUrisValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue(
                          'postLogoutRedirectUris',
                          opt.map((o) => o.value),
                        );
                      }}
                    />
                  </div>
                  <div className={'form-row'}>
                    <Select
                      name={'redirectUris'}
                      label={t('authClients.redirectUris') as string}
                      isMulti={true}
                      allowCreate={true}
                      isClearable={true}
                      options={[]}
                      placeholder={t('authClients.redirectUris') as string}
                      className={'full'}
                      defaultValue={redirectUrisValues}
                      onChange={(options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        setFieldValue(
                          'redirectUris',
                          opt.map((o) => o.value),
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={'modal-bottom'}>
              <Button type='submit' disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
