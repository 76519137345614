import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'urql';
import {
  PimApplicationsDocument,
  PimApplicationsQuery,
  PimApplicationsQueryVariables,
  PimDeleteApplicationDocument,
  PimDeleteApplicationMutation,
  PimDeleteApplicationMutationVariables,
} from '../../client/graphql-client-defs';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { ApplicationsResponse } from './graphql/types';
import { ApplicationsForm } from './Form/ApplicationsForm';

export const Applications = () => {
  const { t } = useTranslation();
  const context = useMemo(() => ({ additionalTypenames: ['Applications'] }), []);

  const [{ data }] = useQuery<PimApplicationsQuery, PimApplicationsQueryVariables>({
    query: PimApplicationsDocument,
    context,
  });
  const [openForm, setOpenForm] = useState(false);
  const [currentApplication, setCurrentApplication] = useState<string | null>(null);
  const [, deleteApplications] = useMutation<PimDeleteApplicationMutation, PimDeleteApplicationMutationVariables>(
    PimDeleteApplicationDocument,
  );

  const createColumns = useMemo((): TableColumns<ApplicationsResponse>[] => {
    return [
      {
        header: t('applications.clientId') as string,
        accessorKey: 'clientId',
        id: 'clientId',
        size: 300,
      },
      {
        header: t('applications.name') as string,
        accessorKey: 'name',
        id: 'name',
        size: 250,
        canExpand: true,
      },
      {
        header: t('applications.licensedOrganizationsCount') as string,
        accessorKey: 'licensedOrganizationsConnection.totalCount',
        id: 'licensedOrganizationsConnection.totalCount',
        size: 150,
      },
    ];
  }, [t]);

  const tableData = useMemo(() => {
    return data?.applications ?? [];
  }, [data]);

  const handleClose = () => {
    setOpenForm(false);
    setCurrentApplication(null);
  };

  const handleEdit = (row: Row<ApplicationsResponse>) => {
    setCurrentApplication(row.original.uuid);
    setOpenForm(true);
  };

  const handleDelete = (row: Row<ApplicationsResponse>) => {
    deleteApplications({ uuid: row.original.uuid }, context);
  };

  useEffect(() => {
    document.title = `Applications`;
  }, []);

  return (
    <div className='page'>
      <Table<ApplicationsResponse>
        data={tableData}
        columns={createColumns}
        showActionBar
        isOnWhite={false}
        actionBarSettings={{
          showAddButton: true,
        }}
        onAddClick={() => {
          setOpenForm(true);
        }}
        lastCol={(row) => (
          <ButtonGroup>
            <Button
              hierarchy='secondary'
              onClick={() => handleEdit(row)}
              icon={<EditIcon className={'svg-icon'} />}
            ></Button>
            <AlertDialog
              title={t('delete.title')}
              confirmText={t('delete.confirmText')}
              cancelText={t('delete.abort')}
              onConfirm={() => handleDelete(row)}
              trigger={<Button hierarchy='secondary' icon={<DeleteIcon className={'svg-icon'} />} />}
            >
              <p>{t('delete.confirmText')}</p>
            </AlertDialog>
          </ButtonGroup>
        )}
      />
      {openForm && (
        <Modal
          isOpen={openForm}
          onRequestClose={handleClose}
          title={currentApplication ? (t('applications.edit') as string) : (t('applications.create') as string)}
          subtitle={currentApplication ?? ''}
        >
          <ApplicationsForm uuid={currentApplication} closeForm={handleClose} />
        </Modal>
      )}
    </div>
  );
};
