import { FieldArray, useFormikContext } from 'formik';
import { ApplicationsResponse } from '../graphql/types';
import { useQuery } from 'urql';
import {
  PimListOrganizationsDocument,
  PimListOrganizationsQuery,
  PimListOrganizationsQueryVariables,
} from '../../../client/graphql-client-defs';
import { useMemo } from 'react';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { Button, DatePicker, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

export type ApplicationLicensedOrganizations = {
  notAfter?: string | null;
  notBefore?: string | null;
  node: { uuid: string; name: string };
};

export const LicensedOrganizationsForm = () => {
  const formik = useFormikContext<ApplicationsResponse>();
  const { t } = useTranslation();
  const organizationContext = useMemo(() => ({ additionalTypenames: ['Organization'] }), []);
  const [result] = useQuery<PimListOrganizationsQuery, PimListOrganizationsQueryVariables>({
    query: PimListOrganizationsDocument,
    context: organizationContext,
  });
  const options = useCreateSelectOptions(
    result.data?.organizations.filter(
      (organization) =>
        !formik.values.licensedOrganizationsConnection.edges.find((value) => value.node.uuid === organization.uuid),
    ),
    'uuid',
    'name',
  );

  return (
    <FieldArray name={'licensedOrganizationsConnection.edges'}>
      {(arrayHelpers) => {
        return (
          <>
            <Select
              isSearchable
              label={t('applications.addLicensedOrganization') as string}
              name={'organization'}
              disabled={options.length === 0}
              options={options}
              value={{ label: '', value: '' }}
              onChange={(option) => {
                const opt = option as SingleValue<SelectOptionType>;
                arrayHelpers.insert(0, {
                  notBefore: undefined,
                  notAfter: undefined,
                  node: { name: opt?.label, uuid: opt?.value },
                } as ApplicationLicensedOrganizations);
              }}
            />
            {formik.values.licensedOrganizationsConnection.edges.map((licensedOrganization, index) => {
              return (
                <div key={licensedOrganization.node.uuid}>
                  <div className={'form-group'}>
                    <div className={'form-col'}>
                      <div className={'form-row'}>
                        <p>{licensedOrganization.node.name}</p>
                      </div>
                    </div>
                  </div>
                  <div className={'form-group'}>
                    <div className={'form-col'}>
                      <div className={'form-row'}>
                        <DatePicker
                          name={`licensedOrganizationsConnection.edges.${index}.notBefore`}
                          label={t('applications.licensedOrganization.notBefore') as string}
                          onChange={(date) => {
                            formik.setFieldTouched(`licensedOrganizationsConnection.edges.${index}.notBefore`, true);
                            formik.setFieldValue(`licensedOrganizationsConnection.edges.${index}.notBefore`, date);
                          }}
                          value={
                            formik.values.licensedOrganizationsConnection.edges[index].properties?.notBefore
                              ? new Date(
                                  formik.values.licensedOrganizationsConnection.edges[index].properties.notBefore,
                                )
                              : undefined
                          }
                          locale={'de'}
                          className={'half'}
                        />
                        <DatePicker
                          name={`licensedOrganizationsConnection.edges.${index}.notAfter`}
                          label={t('applications.licensedOrganization.notAfter') as string}
                          onChange={(date) => {
                            formik.setFieldTouched(`licensedOrganizationsConnection.edges.${index}.notAfter`, true);
                            formik.setFieldValue(`licensedOrganizationsConnection.edges.${index}.notAfter`, date);
                          }}
                          value={
                            formik.values.licensedOrganizationsConnection.edges[index].properties?.notAfter
                              ? new Date(formik.values.licensedOrganizationsConnection.edges[index].properties.notAfter)
                              : undefined
                          }
                          locale={'de'}
                          className={'half'}
                        />
                      </div>
                    </div>
                    <div className={'form-col'}>
                      <Button icon={<DeleteIcon className={'svg-icon'} />} onClick={() => arrayHelpers.remove(index)} />
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      }}
    </FieldArray>
  );
};
