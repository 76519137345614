import React, { Suspense, useContext, useMemo, useState } from 'react';
import { OrganizationContext } from '../../context/OrganizationContext';
import {
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { ShadowsProfilesToConnectionEdgeType } from '../Organizations/graphql/types';
import { ShadowProfilesForm } from './Forms/ShadowProfilesForm/ShadowProfilesForm';
import { useTranslation } from 'react-i18next';
import { usePimUpdateOrganization } from '../../hooks/usePimUpdateOrganization';

export const ShadowProfiles = () => {
  const { organization } = useContext(OrganizationContext);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const [editOrganizationUuid, setEditOrganizationUuid] = useState<string | null>(null);

  const createColumns = (): TableColumns<ShadowsProfilesToConnectionEdgeType>[] => {
    return [
      {
        header: 'Name',
        accessorKey: 'node.name',
        id: 'node.name',
      },
      {
        header: 'OptIn',
        accessorKey: 'optIn',
        id: 'optIn',
      },
    ];
  };

  const tableColumns = useMemo(createColumns, []);
  const tableData: ShadowsProfilesToConnectionEdgeType[] = organization?.shadowsProfilesToConnection.edges ?? [
    {
      properties: { optIn: false, conditions: '' },
      node: { uuid: '', name: '' },
    },
  ];

  const handleClose = () => {
    setEditOrganizationUuid(null);
    setShowForm(false);
  };

  const currentOrganizationUuid = organization?.uuid;
  const update = usePimUpdateOrganization({ organizationUuid: currentOrganizationUuid ?? '' });

  const handleDelete = async (row: Row<ShadowsProfilesToConnectionEdgeType>) => {
    await update({ shadowsProfilesTo: [{ disconnect: [{ where: { node: { uuid: row.original.node.uuid } } }] }] });
  };

  const onEdit = (row: Row<ShadowsProfilesToConnectionEdgeType>) => {
    setEditOrganizationUuid(row.original.node.uuid);
    setShowForm(true);
  };

  return (
    <div className={'page'}>
      <h3>{t('shadow.shadowProfilesFrom', { organization: organization?.name })}</h3>
      <Table<ShadowsProfilesToConnectionEdgeType>
        showSelect={false}
        showActionBar
        isOnWhite={false}
        actionBarSettings={{
          showAddButton: true,
        }}
        onAddClick={() => setShowForm(true)}
        columns={tableColumns}
        data={tableData}
        lastCol={(organizationShadowsProfilesToRelationshipRow) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                onClick={() => onEdit(organizationShadowsProfilesToRelationshipRow)}
                icon={<EditIcon className={'svg-icon'} />}
              ></Button>
              <AlertDialog
                title={t('common.disconnect')}
                confirmText={t('common.disconnect')}
                cancelText={t('common.cancel')}
                onConfirm={() => {
                  handleDelete(organizationShadowsProfilesToRelationshipRow);
                }}
                trigger={<Button hierarchy='secondary' icon={<DeleteIcon className={'svg-icon'} />} />}
              ></AlertDialog>
            </ButtonGroup>
          );
        }}
      />

      {showForm && (
        <Modal isOpen={showForm} onRequestClose={handleClose}>
          <Suspense>
            <ShadowProfilesForm incomingOrganizationUuid={editOrganizationUuid} onClose={() => handleClose()} />
          </Suspense>
        </Modal>
      )}
    </div>
  );
};
