import React, { Suspense, useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'urql';
import { SettingsIcon, Sidebar, SpinnerIcon, Toast } from '@bp/ui-components';
import styles from './App.module.scss';
import { routes } from '../src/config';
import { Route as AppRoute } from '../src/types';
import { PageNotFound } from './routes/PageNotFound/PageNotFound';
import { urqlClient } from './utils/urqlClient';
import { Header } from './ui/Header/Header';
import { RequireAuth } from './RequireAuth';
import { RequireAdmin } from './RequireAdmin';
import { OrganizationContextProvider } from './context/OrganizationContext';
import * as Sentry from '@sentry/react';
import { SidebarContentProps } from '@bp/ui-components/dist/@bp/lib/Sidebar/Sidebar';
import classNames from 'classnames';
import { ListPlainOrganizationsType } from './routes/Organizations/graphql/types';
import { AdminAuthProvider } from './AdminAuthProvider';

function getInitialState() {
  const organization = localStorage.getItem('organization');
  return organization ? JSON.parse(organization) : null;
}

const AppWithoutSentry = () => {
  const addRoute = (route: AppRoute) => <Route key={route.key} path={route.path} element={<route.component />} />;
  const [organization, setOrganization] = useState<ListPlainOrganizationsType | null>(getInitialState());
  const [expandedSidebar, setExpandedSidebar] = useState<boolean>(true);
  const [sidebarFixed, setSidebarFixed] = useState<boolean>(true);

  const contextValue = useMemo(() => {
    return {
      organization,
      setOrganization: (organization: ListPlainOrganizationsType | null) => {
        setOrganization(organization);
        localStorage.setItem('organization', JSON.stringify(organization));
      },
    };
  }, [organization]);

  const location = useLocation();

  const sidebarContent: SidebarContentProps[] = [
    {
      title: 'Administration',
      items: routes
        .filter((route) => {
          return route.inSidebar && !route.onlyOrganisationContext;
        })
        .map(({ link, title, icon }) => {
          return {
            link,
            title,
            icon,
          };
        }),
    },
  ];
  if (organization) {
    sidebarContent.push({
      title: organization.name,
      items: routes
        .filter((route) => {
          return route.inSidebar && route.onlyOrganisationContext;
        })
        .map(({ link, title, icon }) => {
          return {
            link,
            title,
            icon,
          };
        }),
    });
  }

  const wrapperClass = classNames(styles.wrapper, { [styles.fixed]: sidebarFixed });

  return (
    <AdminAuthProvider>
      <RequireAuth>
        <RequireAdmin>
          <Provider value={urqlClient}>
            <OrganizationContextProvider value={contextValue}>
              <div className={wrapperClass} data-cy='layoutWrapper'>
                <Sidebar
                  currentLocationPath={location.pathname}
                  isExpanded={expandedSidebar}
                  isFixed={sidebarFixed}
                  logoType={'admin'}
                  setIsExpanded={setExpandedSidebar}
                  setIsFixed={setSidebarFixed}
                  sidebarContent={sidebarContent}
                  sidebarFooterItems={[
                    {
                      icon: <SettingsIcon />,
                      title: 'Settings',
                      link: '/settings',
                    },
                  ]}
                />
                <div className={styles.content} data-cy='layoutContentColumn'>
                  <Header />
                  <main className={styles.main}>
                    <div className={styles.container}>
                      <Suspense fallback={<SpinnerIcon className={'svg-icon large spinning'} />}>
                        <Routes>
                          {routes.map((route: AppRoute) =>
                            route.subRoutes ? route.subRoutes.map((item: AppRoute) => addRoute(item)) : addRoute(route),
                          )}
                          <Route path='*' element={<PageNotFound />} />
                        </Routes>
                      </Suspense>
                    </div>
                  </main>
                </div>
              </div>
              <Toast />
            </OrganizationContextProvider>
          </Provider>
        </RequireAdmin>
      </RequireAuth>
    </AdminAuthProvider>
  );
};

export const App = Sentry.withProfiler(AppWithoutSentry);
