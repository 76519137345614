import React, { useEffect, useState } from 'react';
import { AlertDialog, Button, Card, DatePicker, DeleteIcon, Input, Switch } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { bpApi } from '../../../utils/bpClient';
import { ZoomResponse, ZoomSettingsRequest, ZoomSettingsResponse } from '../../../types/zoom';
import {
  PimSetOrganizationPropertyDocument,
  PimSetOrganizationPropertyMutation,
  PimSetOrganizationPropertyMutationVariables,
  PimUnsetOrganizationPropertyDocument,
  PimUnsetOrganizationPropertyMutation,
  PimUnsetOrganizationPropertyMutationVariables,
} from '../../../client/graphql-client-defs';
import { useMutation } from 'urql';

interface OrganizationZoomFormProps {
  uuid: string;
  afterSubmit: () => void;
}

type OrganizationZoomFormTypes = {
  zoomKey: string;
  zoomSecret: string;
  enabledUntil: string;
  recordings?: boolean;
};

export const OrganizationZoomForm = ({ uuid, afterSubmit }: OrganizationZoomFormProps) => {
  const [zoomSettings, setZoomSettings] = useState<ZoomSettingsResponse | null>(null);
  const [, setOrganizationProperty] = useMutation<
    PimSetOrganizationPropertyMutation,
    PimSetOrganizationPropertyMutationVariables
  >(PimSetOrganizationPropertyDocument);
  const [, unsetOrganizationProperty] = useMutation<
    PimUnsetOrganizationPropertyMutation,
    PimUnsetOrganizationPropertyMutationVariables
  >(PimUnsetOrganizationPropertyDocument);

  useEffect(() => {
    if (uuid) {
      const getZoomData = async (uuid: string) => {
        const response = await bpApi.get<ZoomSettingsResponse>(`/api/zoom/school-settings`, {
          'school-uuid': uuid,
        });
        setZoomSettings(response);
      };
      getZoomData(uuid);
    }
  }, [uuid]);
  if (zoomSettings === null) {
    return <h1>LOADING....</h1>;
  }

  const handleSubmit = async (values: OrganizationZoomFormTypes, helpers: FormikHelpers<OrganizationZoomFormTypes>) => {
    async function fetchData() {
      try {
        const data: ZoomSettingsRequest = {
          key: values.zoomKey,
          secret: values.zoomSecret,
          enabledUntil: values.enabledUntil,
          schoolUuid: uuid,
          recordings: values.recordings,
        };
        if (zoomSettings && zoomSettings.data) {
          return await bpApi.put<BodyInit, ZoomResponse>(`/api/zoom/school-settings`, JSON.stringify(data));
        } else {
          return await bpApi.post<BodyInit, ZoomResponse>(`/api/zoom/school-settings`, JSON.stringify(data));
        }
      } catch (e) {
        console.log(e);
      }
    }

    const response = await fetchData();
    // TODO add error handling
    if (response && response.success) {
      // TODO move to BE
      await setOrganizationProperty({
        name: 'zoom-enabled',
        value: 'true',
        organizationUuid: uuid,
        scope: 'bp',
      });
      afterSubmit();
      helpers.resetForm();
    }
  };

  const handleDelete = async () => {
    try {
      const { success } = await bpApi.delete<ZoomResponse>(`/api/zoom/school-settings`, {
        'school-uuid': uuid,
      });
      if (success) {
        await unsetOrganizationProperty({
          name: 'zoom-enabled',
          organizationUuid: uuid,
          scope: 'bp',
        });
      }
      afterSubmit();
    } catch (e) {
      console.log(e);
    }
  };

  const { success: zoomSuccess, data: zoomSettingsData } = zoomSettings;
  const initialValues: OrganizationZoomFormTypes =
    zoomSuccess && zoomSettingsData
      ? {
          zoomKey: zoomSettingsData.zoomKey,
          zoomSecret: zoomSettingsData.zoomSecret,
          enabledUntil: zoomSettingsData.zoomEnabledUntil,
          recordings: zoomSettingsData.zoomRecordingsEnabled,
        }
      : {
          zoomKey: '',
          zoomSecret: '',
          enabledUntil: '',
          recordings: false,
        };

  return (
    <>
      <Card>
        {zoomSettingsData && (
          <AlertDialog
            title={'Delete'}
            confirmText={'Delete?'}
            cancelText={'Cancel'}
            onConfirm={() => handleDelete()}
            trigger={
              <Button hierarchy='secondary' icon={<DeleteIcon className={'svg-icon'} />}>
                Delete
              </Button>
            }
          >
            <p>Delete?</p>
          </AlertDialog>
        )}
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleChange, values, isSubmitting, errors, setFieldValue, setFieldTouched }) => {
            return (
              <Form>
                <div className={'form-block'}>
                  <div className={'form-group'}>
                    <div className={'form-col'}>
                      <Input label={'Zoom Key'} onChange={handleChange} name={'zoomKey'} value={values.zoomKey}></Input>
                      <Input
                        label={'Zoom Secret'}
                        onChange={handleChange}
                        name={'zoomSecret'}
                        value={values.zoomSecret}
                      ></Input>
                      <DatePicker
                        dateFormat='dd.MM.yyyy'
                        name={'enabledUntil'}
                        label={'Enabled until'}
                        value={values.enabledUntil ? new Date(values.enabledUntil) : undefined}
                        onChange={(date) => {
                          setFieldValue('enabledUntil', date);
                        }}
                        onBlur={() => {
                          setFieldTouched('enabledUntil', true);
                        }}
                      />
                      <p>Enable recordings</p>
                      <Switch name={'recordings'} checked={values.recordings} onChange={handleChange}></Switch>
                    </div>
                  </div>
                </div>
                <div className={'modal-bottom'}>
                  <Button type='submit' disabled={isSubmitting || (errors && Object.values(errors).length > 0)}>
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </>
  );
};
