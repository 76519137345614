import { STORAGE_KEY } from './constants';

import { b64utos, KJUR } from 'jsrsasign';
import { PROFILE_CLAIM, ProfileClaim, RoleClaim, ROLES_CLAIM } from '@bp/pim-auth-constants';

export interface IpClaim {
  at_hash?: string;
  sub?: string;
  aud?: string;
  iss?: string;
  exp?: number;
  iat?: number;
  'https://claims.pim.tks.eu/profile'?: ProfileClaim;
  'https://claims.pim.tks.eu/roles'?: RoleClaim[];
}

const readToken = (token: string | null): IpClaim | null => {
  let pClaim = null;
  if (token) {
    // https://github.com/kjur/jsrsasign/blob/master/tool/tool_jwt.html
    const t = token.split('.');
    const uClaim = b64utos(t[1]);
    pClaim = KJUR.jws.JWS.readSafeJSONString(uClaim);
  }

  return pClaim;
};

const getAuthData = (): string | null => {
  return sessionStorage.getItem(STORAGE_KEY);
};

export const getAccessToken = (): string | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).access_token;
  }
  return null;
};

export const getRefreshToken = () => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).refresh_token;
  }
  return null;
};

export const getExpiresAt = (): number | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).expires_at;
  }
  return null;
};

export const getIdToken = (): string | null => {
  const data = getAuthData();
  if (data) {
    return JSON.parse(data).id_token;
  }
  return null;
};

export const getUUID = (): string | null => {
  const tokenAsJson = readToken(getIdToken());
  return tokenAsJson?.sub ?? null;
};

export const getRoles = (): RoleClaim[] => {
  const tokenAsJson = readToken(getIdToken());

  if (tokenAsJson && tokenAsJson[ROLES_CLAIM as keyof IpClaim])
    return tokenAsJson[ROLES_CLAIM as keyof IpClaim] as RoleClaim[];

  return [];
};

export const getProfile = (): ProfileClaim | undefined => {
  const tokenAsJson = readToken(getIdToken());

  if (tokenAsJson && tokenAsJson[PROFILE_CLAIM as keyof IpClaim])
    return tokenAsJson[PROFILE_CLAIM as keyof IpClaim] as ProfileClaim;
};

export const getTokens = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  const expiresAt = getExpiresAt();
  const idToken = getIdToken();
  // TODO check refresh token
  if (accessToken && refreshToken) {
    return { access_token: accessToken, refresh_token: refreshToken, expires_at: expiresAt, id_token: idToken };
  }
  return null;
};

export const clearTokens = () => {
  sessionStorage.clear();
  return sessionStorage.removeItem(STORAGE_KEY);
};
