import { useQuery } from 'urql';
import {
  PimListGroupsDocument,
  PimListGroupsQuery,
  PimListGroupsQueryVariables,
  SortDirection,
} from '../../../../client/graphql-client-defs';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { Select, SelectOptionType } from '@bp/ui-components';
import { useField, useFormikContext } from 'formik';
import { ShadowProfileFromType } from '../ShadowProfilesForm/ShadowProfilesForm';
import { SingleValue } from 'react-select';
import { PropsWithChildren, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationContext } from '../../../../context/OrganizationContext';
import { HasGroupClause } from '@bp/pim-shadow-conditions';

type GroupsSelectType = PropsWithChildren & {
  name: string;
};

export const GroupsSelect = ({ name }: GroupsSelectType) => {
  const formik = useFormikContext<ShadowProfileFromType>();
  const { organization } = useContext(OrganizationContext);
  const [field] = useField<HasGroupClause>(name);
  const [{ data }] = useQuery<PimListGroupsQuery, PimListGroupsQueryVariables>({
    query: PimListGroupsDocument,
    variables: {
      options: {
        sort: [{ name: SortDirection.Asc }],
      },
      where: {
        organization: {
          uuid: organization?.uuid,
        },
      },
    },
  });

  const groups = data?.groups;
  const options = useCreateSelectOptions(groups, 'uuid', 'name');
  const { t } = useTranslation();
  return (
    <Select
      defaultValue={options.find((opt) => opt.value === field.value.uuid)}
      className={'quarter'}
      label={t('groups.title') as string}
      name={name}
      allowCreate={true}
      options={options}
      onChange={(options) => {
        const o = options as SingleValue<SelectOptionType>;
        formik.setFieldValue(name, { relation: 'HAS_GROUP', uuid: o?.value } as HasGroupClause);
      }}
    />
  );
};
