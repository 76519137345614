import React, { useContext, useState } from 'react';
import styles from './Header.module.css';
import { UserIcon } from '../UserIcon/UserIcon';
import { OrganizationContext } from '../../context/OrganizationContext';
import { Button, Dropdown, QuestionmarkCircleIcon } from '@bp/ui-components';
import { OrganizationSelect } from '../OrganizationSelect/OrganizationSelect';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { AboutModal } from '../AboutModal/AboutModal';

export const Header = () => {
  const auth = useAuth();
  const { organization, setOrganization } = useContext(OrganizationContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { t } = useTranslation();
  return (
    <header className={styles.header} data-cy='headerWrapper'>
      <div className={styles.container}>
        <div className={styles.left}>Administration</div>
        <OrganizationSelect
          onChange={(organization) => setOrganization(organization)}
          defaultValue={organization?.name ? { value: organization?.uuid, label: organization?.name } : undefined}
          emptyLabel={t('common.select.organization') as string}
        />
        <div className={styles.right}>
          <Button
            className={styles.about}
            hierarchy='tertiary'
            icon={<QuestionmarkCircleIcon className='svg-icon' onClick={() => setShowModal(true)} />}
          />
          <Dropdown trigger={<UserIcon />}>
            <Button hierarchy='tertiary' onClick={() => auth.signoutRedirect()}>
              Logout
            </Button>
          </Dropdown>
        </div>
      </div>
      {showModal && <AboutModal state={showModal} setState={(value) => setShowModal(value)} />}
    </header>
  );
};
