import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@bp/ui-components';
import { ModalBottomButtons } from '../components/ModalBottomButtons/ModalBottomButtons';

interface UseConfirmProps {
  defaultTitle?: string;
  defaultMessage?: string;
  defaultConfirmText?: string;
  defaultCancelText?: string;
  onConfirm?: () => Promise<void>;
}

interface ConfirmProps {
  title?: string;
  message?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => Promise<void>;
}

export const useConfirm = (props?: UseConfirmProps) => {
  const [promise, setPromise] = useState<null | { resolve: (value: boolean) => void }>(null);
  const [confirmText, setConfirmText] = useState<string | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [message, setMessage] = useState<ReactNode | null>(null);
  const [cancelText, setCancelText] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [onConfirm, setOnConfirm] = useState<() => Promise<void>>();

  const { t } = useTranslation();
  const confirm = async (props?: ConfirmProps): Promise<boolean> => {
    if (props?.title) {
      setTitle(props?.title);
    }
    if (props?.message) {
      setMessage(props?.message);
    }
    if (props?.confirmText) {
      setConfirmText(props?.confirmText);
    }
    if (props?.cancelText) {
      setCancelText(props?.cancelText);
    }
    if (props?.onConfirm) {
      setOnConfirm(() => props.onConfirm);
    }
    return new Promise((resolve, reject) => {
      setPromise({ resolve });
    });
  };

  useEffect(() => {
    setConfirmText(props?.defaultConfirmText ?? null);
  }, [props?.defaultConfirmText]);

  useEffect(() => {
    setTitle(props?.defaultTitle ?? null);
  }, [props?.defaultTitle]);

  useEffect(() => {
    setMessage(props?.defaultMessage ?? null);
  }, [props?.defaultMessage]);

  useEffect(() => {
    setCancelText(props?.defaultCancelText ?? null);
  }, [props?.defaultCancelText]);

  useEffect(() => {
    if (props?.onConfirm) {
      setOnConfirm(() => props.onConfirm);
    }
  }, [props?.onConfirm]);

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = async () => {
    if (onConfirm) {
      setLoading(true);
      await onConfirm();
      setLoading(false);
    }
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Modal
      isOpen={promise !== null}
      title={title ?? t('common.delete')}
      onRequestClose={handleCancel}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      height={'m'}
      width={'s'}
    >
      {message}
      <ModalBottomButtons
        submitButton={{ callback: handleConfirm, text: confirmText ?? t('common.confirm') }}
        closeButton={{ callback: handleCancel, text: cancelText ?? t('common.cancel') }}
        errors={false}
        isLoading={loading}
      />
    </Modal>
  );
  return { ConfirmationDialog, confirm };
};
