import React, { useContext, useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Input } from '@bp/ui-components';
import { OrganizationContext } from '../../../context/OrganizationContext';
import { connectByUuid } from '../../../utils/connectLib';
import {
  PimCreateGroupDocument,
  PimCreateGroupMutation,
  PimCreateGroupMutationVariables,
  PimListGroupsDocument,
  PimListGroupsQuery,
  PimListGroupsQueryVariables,
  PimUpdateGroupDocument,
  PimUpdateGroupMutation,
  PimUpdateGroupMutationVariables,
} from '../../../client/graphql-client-defs';
import { useMutation, useQuery } from 'urql';
import { PropertiesArrayField } from '../../Properties/PropertiesForm/PropertiesArrayField';

type GroupsResponseType = Pick<PimListGroupsQuery, 'groups'>['groups'][number];

interface GroupsFormProps {
  groupUuid: string;
  afterSubmit: (error?: { message: string; name: string }) => void;
}
export const GroupsForm = ({ groupUuid, afterSubmit }: GroupsFormProps) => {
  const { organization } = useContext(OrganizationContext);
  const context = useMemo(() => ({ additionalTypenames: ['Group'] }), []);

  const [, updateGroup] = useMutation<PimUpdateGroupMutation, PimUpdateGroupMutationVariables>(PimUpdateGroupDocument);
  const [, createGroup] = useMutation<PimCreateGroupMutation, PimCreateGroupMutationVariables>(PimCreateGroupDocument);
  const [{ data, fetching }] = useQuery<PimListGroupsQuery, PimListGroupsQueryVariables>({
    query: PimListGroupsDocument,
    context,
    variables: {
      options: {
        limit: 1,
        offset: 0,
      },
      where: {
        uuid: groupUuid,
      },
      organizationUuid: organization?.uuid,
    },
  });

  const initialValue: GroupsResponseType = {
    uuid: '',
    name: '',
    profiles: [],
    propertiesConnection: { edges: [] },
    profilesConnection: {
      totalCount: 0,
      __typename: 'GroupProfilesConnection',
    },
    __typename: 'Group',
  };

  const group: GroupsResponseType =
    data && data.groups ? (data.groups.length > 0 ? data.groups[0] : initialValue) : initialValue;

  const handleSubmit = async (values: GroupsResponseType, formHelpers: FormikHelpers<GroupsResponseType>) => {
    const { uuid, name } = values;
    const { error } = !organization
      ? { error: { message: '', name: '' } }
      : uuid !== ''
        ? await updateGroup(
            {
              update: {
                name: name,
                properties: [
                  {
                    delete: [{}],
                    create: values.propertiesConnection.edges.map((prop) => ({
                      edge: { scope: prop.properties.scope },
                      node: { name: prop.node.name, value: prop.node.value },
                    })),
                  },
                ],
              },
              where: {
                uuid: uuid,
              },
            },
            context,
          )
        : await createGroup(
            {
              input: {
                name: name,
                organization: connectByUuid(organization.uuid),
                properties: {
                  create: values.propertiesConnection.edges.map((prop) => {
                    return {
                      edge: { scope: prop.properties.scope },
                      node: { name: prop.node.name, value: prop.node.value },
                    };
                  }),
                },
              },
            },
            context,
          );
    afterSubmit(error ? { message: error.message, name: error.name } : undefined);
    if (!error) {
      formHelpers.resetForm();
    }
  };
  return (
    <Formik onSubmit={handleSubmit} initialValues={group}>
      {({ handleChange, values, isSubmitting, errors }) => {
        return (
          <Form>
            <div className={'form-block'}>
              <div className={'form-group'}>
                <div className={'form-col'}>
                  <div className={'form-row'}>
                    <Input
                      name={'name'}
                      value={values.name as string}
                      label={'Name'}
                      onChange={handleChange}
                      required={true}
                      className={'full'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <PropertiesArrayField />
            <div className={'modal-bottom'}>
              <Button type='submit' disabled={isSubmitting || fetching || (errors && Object.values(errors).length > 0)}>
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
